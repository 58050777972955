import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { api } from './../../services/api';
import { Typography } from "@mui/material";
import moment from "moment";

export default function RecognitionStatus({ id,setDic_Status=()=>{} }) {
  const [trackRecognition] =
    api.endpoints.trackRecognitionStatus.useLazyQuery();
  const [dicStatus, setDicStatus] = React.useState('');
  const [hodStatus, setHodStatus] = React.useState('');
  const [userData, setUserData] = React.useState('');
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackRecognition(id);
          if (data?.success) {
            setDicStatus(data?.application?.dicstatus);
            setDic_Status(data?.application?.dicstatus)
            setHodStatus(data?.application?.directoratestatus);
            setUserData(data?.application);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color={dicStatus==='accepted'?"success":dicStatus==='reverted'?"error":"grey"} />
          <TimelineConnector  sx={{
              backgroundColor:
              dicStatus === "accepted"
                  ? "green"
                  : dicStatus === "reverted"
                  ? "red"
                  : "grey",
            }}/>
        </TimelineSeparator>
        <TimelineContent>GM/DIC Status
       {userData?.dicuser?.name!==undefined?<Typography
            variant="h6"
            component="div"
            // textAlign="center"
            fontSize={14}
            fontWeight="bold"
            fontFamily="Poppins, sans-serif"
            color="primary.secondary"
          >
            {"Name: " + userData?.dicuser?.name}
          </Typography>:""}
          {userData?.dicremark!==null && userData?.dicremark!==undefined?<Typography
           variant="h6"
            component="div"
            fontSize={12}
            fontFamily="Poppins, sans-serif"
            color="primary.main" // Default color
            sx={{
              "& .highlight": { color: "green" }, // Set your custom color here
            }}
          >
            <span className="highlight">{"Remarks: " + userData?.dicremark}</span>
          </Typography>:""}
          {userData?.dicstatusdate!==null ?<Typography
            variant="h6"
            component="div"
            //  textAlign="center"
            fontSize={12}
            //  fontWeight="bold"
            fontFamily="Poppins, sans-serif"
            color="primary.main"
          >
            {"Date: " + moment(userData?.dicstatusdate).format("LL")}
          </Typography>:""}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot  color={hodStatus==='approved'?"success":hodStatus==='rejected'?"error":"grey"} />
          {/* <TimelineConnector /> */}
        </TimelineSeparator>
        <TimelineContent>
          HOD/Directorate Status
          {userData?.directorateuser?.name!==undefined ?<Typography
            variant="h6"
            component="div"
            // textAlign="center"
            fontSize={14}
            fontWeight="bold"
            fontFamily="Poppins, sans-serif"
            color="primary.secondary"
          >
            {"Name: " + userData?.directorateuser?.name}
          </Typography>:""}
          { userData?.directorateremark!==null && userData?.directorateremark!==undefined?<Typography
            variant="h6"
            component="div"
            fontSize={12}
            fontFamily="Poppins, sans-serif"
            color="primary.main" // Default color
            sx={{
              "& .highlight": { color: "green" }, // Set your custom color here
            }}
          >
            <span className="highlight">{"Remarks: " + userData?.directorateremark}</span>
          </Typography>:""}
          {userData?.directoratestatusdate!==null?<Typography
            variant="h6"
            component="div"
            //  textAlign="center"
            fontSize={12}
            //  fontWeight="bold"
            fontFamily="Poppins, sans-serif"
            color="primary.main"
          >
            {"Date: " + moment(userData?.directoratestatusdate).format("LL")}
          </Typography>:""}
        </TimelineContent>
      </TimelineItem>
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot  color={status==='approved'?"success":"grey"} />
        </TimelineSeparator>
        <TimelineContent>HOD/Directorate Status</TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}
