import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { api } from "../../services/api";
import { Typography } from "@mui/material";
import moment from "moment";

export default function TrackPR({ id }) {
  //director === hod
  const [trackPR] = api.endpoints.trackProgressReportStatus.useLazyQuery();
  const [nodalStatus, setNodalStatus] = React.useState("");
  const [techStatus, setTechStatus] = React.useState("");
  const [mentorStatus, setMentorStatus] = React.useState("");
  const [dicStatus, setDicStatus] = React.useState("");
  const [hodStatus, setHodStatus] = React.useState("");
  const [userData, setUserData] = React.useState("");
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackPR(id);
          if (data?.success) {
            setNodalStatus(data?.application?.nodal_status);
            setMentorStatus(data?.application?.mentor_status);
            setDicStatus(data?.application?.dic_status);
            setHodStatus(data?.application?.directorate_status);
            setUserData(data?.application);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              techStatus === "selected"
                ? "success"
                : techStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                techStatus === "selected"
                  ? "green"
                  : techStatus === "rejected"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>Technical Screeen Status</TimelineContent>
      </TimelineItem> */}
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              mentorStatus === "accepted"
                ? "success"
                : mentorStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                mentorStatus === "accepted"
                  ? "green"
                  : mentorStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          Mentor Status
          {userData?.mentor_user?.name !== undefined?<Typography
            variant="h6"
            component="div"
            // textAlign="center"
            fontSize={14}
            fontWeight="bold"
            fontFamily="Poppins, sans-serif"
            color="primary.secondary"
          >
            {"Name: " +  userData?.mentor_user?.name}
          </Typography>:""}
          {userData?.mantor_comment !== null && userData?.mantor_comment !== undefined?<Typography
            variant="h6"
            component="div"
            fontSize={12}
            fontFamily="Poppins, sans-serif"
            color="primary.main" // Default color
            sx={{
              "& .highlight": { color: "green" }, // Set your custom color here
            }}
          >
            <span className="highlight">
              {"Remarks: " + 
               userData?.mantor_comment}
            </span>
          </Typography>:""}
          {userData?.mentor_date !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.mentor_date).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              nodalStatus === "accepted"
                ? "success"
                : nodalStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                nodalStatus === "accepted"
                  ? "green"
                  : nodalStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          Nodal Status
          {userData?.nodal_user?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.nodal_user?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.nodal_comment !== null  && userData?.nodal_comment !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.nodal_comment}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.nodal_date !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.nodal_date).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              dicStatus === "accepted"
                ? "success"
                : dicStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                dicStatus === "accepted"
                  ? "green"
                  : dicStatus === "rejected"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          DIC/GM Status
          {userData?.dic_user?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.dic_user?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.dic_comment !== null && userData?.dic_comment !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.dic_comment}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.dic_date !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.dic_date).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              hodStatus === "approved"
                ? "success"
                : hodStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
        </TimelineSeparator>
        <TimelineContent>
          HOD/Directorate Status
          {userData?.directorate_user?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.directorate_user?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.directorate_comment !== null && userData?.directorate_comment !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.directorate_comment}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.directorate_date !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.directorate_date).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
