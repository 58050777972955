import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import validationSchema from "./validationSchema";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  api,
  useCreateApplicantMutation,
  useCreateRecognitionMutation,
  useGetBlockQuery,
  useGetDistrictQuery,
  useGetLegislativeQuery,
  useGetPCQuery,
  useGetStateQuery,
  useGetTehsilQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../services/ThemeContext";

const FounderDetails = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("recognition_id");
  const [loading, setLoading] = React.useState(false);
  const { data: lacList, isLoading, isError } = useGetLegislativeQuery();
  const { data: pcList } = useGetPCQuery();
  const { data: stateList } = useGetStateQuery();
  const { data: districtList } = useGetDistrictQuery();
  const { data: tehsilList } = useGetTehsilQuery();
  const { data: blockList } = useGetBlockQuery();
  const [recognitionCreate] = useCreateRecognitionMutation();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const [copyToRecognition] = api.endpoints.copyToRecognition.useLazyQuery();
  const { state } = useLocation();
  const {fontSize } = useContext(ThemeContext);
  const [lacData] = api.endpoints.getLegislativeDistrictWise.useLazyQuery();
  const [contiTencyData] = api.endpoints.getParlimentContiWise.useLazyQuery();
  const [tehsilDataDistrictWise] = api.endpoints.getTehsilDistrictWise.useLazyQuery();


  const formik = useFormik({
    initialValues: {
      name_of_the_founder: "",
      name_of_the_cofounder: "",
      contact: "",
      pan: "",
      aadhaar: "",
      address_of_the_founder: "",
      lac: "",
      parliment: "",
      constituency:"",
      lacList:[],
      constiList:[],

      
      blockList:[],
      tehsilList:[],
      state: "",
      district: "",
      tehsil: "",
      block: "",
      pin: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let data = {
          step: "founder",
          payload: {
            founder_name: values.name_of_the_founder,
            co_founders: values?.name_of_the_cofounder,
            contact: values?.contact,
            pan: values.pan,
            aadhaar: values?.aadhaar,
            founder_address: values.address_of_the_founder,
            founder_lac: values?.lac,
            founder_parliament: values?.parliment,
            founder_constituency: values?.constituency,
            founder_state: values?.state,
            founder_district: values?.district,
            founder_teshil: values?.tehsil,
            founder_block: values?.block,
            founder_pin: values?.pin,
            application_id: _id,
          },
        };

        const response = await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success(
            _id
              ? "Founder Details Updated Sucessfully"
              : "Founder Details Created Sucessfully",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });
  const handleNext = () => {
    formik.handleSubmit();
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };
  // const handleDistrictChange = async (id) => {
  //   formik.setFieldValue("district", id);
  //   const respose = await lacData(id);
  //   if (respose?.data?.success) {
  //     formik.setFieldValue("lacList", respose?.data?.data);
  //   }
  // };
  const handleDistrictChange = async (id) => {
    formik.setFieldValue("district", id);
    const respose = await lacData(id);
    const res = await tehsilDataDistrictWise(id);
    if (respose?.data?.success) {
      formik.setFieldValue("lacList", respose?.data?.data);
    }
    if(res?.data?.success) {
      formik.setFieldValue("tehsilList", res.data?.data?.tehsil);
      formik.setFieldValue("blockList", res?.data?.data?.block);

    }
  };
  const handleParChange = async (id) => {
    formik.setFieldValue("parliment", id);
    // try {
    //   const response = await contiTencyData(id);
    //   if (response?.data?.success) {
    //     formik.setFieldValue("constiList", response?.data?.data);
    //   }
    // } catch (error) {
    //   console.error("Error fetching constituencies:", error);
    // }
  };
  React.useEffect(() => {
    const UpdateApplicant = async () => {
      if (state) {
        try {
          const { data } = await copyToRecognition(state);
          const {
            founder_name,
            co_founders,
            contact,
            pan,
            aadhaar,
            founder_address,
            founder_lac,
            founder_parliament_constituency,
            founder_state,
            founder_district,
            founder_teshil,
            founder_block,
            founder_pin,
          } = data?.data;
          await formik.setValues((prev) => ({
            ...prev,
            name_of_the_founder: founder_name,
            name_of_the_cofounder: co_founders,
            contact,
            pan,
            aadhaar,
            address_of_the_founder: founder_address,
            lac: founder_lac,
            pc: founder_parliament_constituency,
            state: founder_state,
            district: founder_district,
            tehsil: founder_teshil,
            block: founder_block,
            pin: founder_pin,
          }));
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplicant();
  }, []);
  React.useEffect(() => {
    const UpdateApplicant = async () => {
      if (_id) {
        try {
          const { data } = await getSingleRecognition(_id);
          const {
            founder_name,
            co_founders,
            contact,
            pan,
            aadhaar,
            founder_address,
            founder_lac,
            founder_parliament,
            founder_constituency,
            founder_state,
            founder_district,
            founder_teshil,
            founder_block,
            founder_pin,
          } = data?.data;
          
          await formik.setValues((prev) => ({
            ...prev,
            name_of_the_founder: founder_name,
            name_of_the_cofounder: co_founders,
            contact,
            pan,
            aadhaar,
            address_of_the_founder: founder_address,
            lac: founder_lac,
            parliment: founder_parliament,
            constituency:founder_constituency,
            state: founder_state,
            district: founder_district,
            tehsil: founder_teshil,
            block: founder_block,
            pin: founder_pin,
          }));
          if (founder_district) {
            await handleDistrictChange(founder_district);
          }
          if (founder_parliament) {
           await handleParChange(founder_parliament);
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplicant();
  }, [_id]);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Founder Details
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Type of Project */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Name of the Founder:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="name_of_the_founder"
                label="Name of the Startup"
                name="name_of_the_founder"
                autoComplete="Company Name"
                value={formik.values.name_of_the_founder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_founder &&
                  Boolean(formik.errors.name_of_the_founder)
                }
                helperText={
                  formik.touched.name_of_the_founder &&
                  formik.errors.name_of_the_founder
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Name of the Co-Founder:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="name_of_the_cofounder"
                label="Name of the Co Founder"
                name="name_of_the_cofounder"
                autoComplete="off"
                value={formik.values.name_of_the_cofounder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_cofounder &&
                  Boolean(formik.errors.name_of_the_cofounder)
                }
                helperText={
                  formik.touched.name_of_the_cofounder &&
                  formik.errors.name_of_the_cofounder
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Contact:
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              required
              id={`contact`}
              label="Contact"
              name={`contact`}
              placeholder="Contact"
              autoComplete={"off"}
              value={formik.values?.contact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contact && Boolean(formik.errors.contact)}
              helperText={formik.touched.contact && formik.errors.contact}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              PAN:
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              required
              id={`pan`}
              label="PAN"
              name={`pan`}
              placeholder="PAN"
              autoComplete={"off"}
              value={formik.values?.pan}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pan && Boolean(formik.errors.pan)}
              helperText={formik.touched.pan && formik.errors.pan}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              AADHAAR:
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              required
              id={`aadhaar`}
              label="Aadhaar"
              name={`aadhaar`}
              placeholder="Aadhaar"
              autoComplete={"off"}
              value={formik.values?.aadhaar}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.aadhaar && Boolean(formik.errors.aadhaar)}
              helperText={formik.touched.aadhaar && formik.errors.aadhaar}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Address of the Founder:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="address_of_the_founder"
                label="Address of the Founder"
                name="address_of_the_founder"
                autoComplete="off"
                type="textarea"
                // rows={2}
                multiline
                value={formik.values.address_of_the_founder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.address_of_the_founder &&
                  Boolean(formik.errors.address_of_the_founder)
                }
                helperText={
                  formik.touched.address_of_the_founder &&
                  formik.errors.address_of_the_founder
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              State/ UT:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="state">State/ UT</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="state"
                label="State"
                placeholder="State"
                name="state"
                // autoFocus
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {stateList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: "18px" }}>
              District:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="district">District</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="district"
                label="District"
                name="district"
                value={formik.values.district}
                onChange={(e) => handleDistrictChange(e.target.value)}
                onBlur={formik.handleBlur}
                error={formik.touched.district && Boolean(formik.errors.district)}
                // helperText={formik.touched.district && formik.errors.district}
              >
                {districtList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Tehsil:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Tehsil</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="tehsil"
                label="tehsil"
                placeholder="Tehsil"
                name="tehsil"
                // autoFocus
                value={formik.values.tehsil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                // helperText={formik.touched.tehsil && formik.errors.tehsil}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                 {formik?.values?.tehsilList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Block:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="block"
                label="block"
                placeholder="Block"
                name="block"
                // autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                // helperText={formik.touched.block && formik.errors.block}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                 {formik?.values?.blockList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Legislative Assembly Constituency:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="lac">
                Legislative Assembly Constituency
              </InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="lac"
                label="Legislative Assembly Constituency"
                placeholder="Legislative Assembly Constituency"
                name="lac"
                // autoFocus
                value={formik.values.lac}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lac && Boolean(formik.errors.lac)}
                // helperText={formik.touched.lac && formik.errors.lac}
                autoComplete="Register type"
              >
                {formik?.values?.lacList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Parliament Constituency:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="parliment">Parliament Constituency</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="parliment"
                label="Parliament Constituency"
                placeholder="Parliament"
                name="parliment"
                // autoFocus
                value={formik.values.parliment}
                onChange={(e) => {
                  handleParChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.parliment && Boolean(formik.errors.parliment)}
                // helperText={formik.touched.parliment && formik.errors.parliment}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
                
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: 400, fontSize: "18px" }}>
              Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="constituency">Constituency</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="constituency"
                label="Constituency"
                name="constituency"
                value={formik.values.constituency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.constituency && Boolean(formik.errors.constituency)}
                helperText={formik.touched.constituency && formik.errors.constituency}
              >
                {formik.values.constiList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

       
         
          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Block:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>  
              <Select
                margin="dense"
                required
                fullWidth
                id="block"
                label="block"
                placeholder="block"
                name="block"
                // autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                helperText={formik.touched.block && formik.errors.block}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {blockList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              PIN:
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              required
              id={`pin`}
              label="PIN"
              name={`pin`}
              placeholder="PIN"
              autoComplete={"off"}
              value={formik.values?.pin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.pin && Boolean(formik.errors.pin)}
              helperText={formik.touched.pin && formik.errors.pin}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

        {/* Form Actions */}
      </Box>
    </>
  );
};

export default FounderDetails;
