import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useState, useMemo } from "react";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material/";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  api,
  useDeleteApplicantMutation,
  useDeleteRecoMutation,
  useGetApplicationQuery,
  useGetDistrictQuery,
  useGetRecognitionQuery,
  useUpdateCategoryMutation,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";
import "jspdf-autotable";
import generateCertificate from "../../Helpers/DownloadCertificate";
import LoadingButton from "@mui/lab/LoadingButton";
import ArticleIcon from "@mui/icons-material/Article";
import LoaderFallBack from "../../Loader";
import RestoreIcon from '@mui/icons-material/Restore';
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const RecognitionIndex = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [district, setDistrict] = useState("");
  const { data: recognitionList, isLoading } = useGetRecognitionQuery({
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    data: `${district ? district : null}`,
  });
  const [applicantDelete] = useDeleteApplicantMutation();
  const [categoryUpdate] = useUpdateCategoryMutation();
  const role = localStorage.getItem("role").toLowerCase();
  const { data: districtList } = useGetDistrictQuery();
  const [recoDelete] = useDeleteRecoMutation();
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Recognition Application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        recoDelete(id);
        swal("Recognition Application is deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("Recognition Application is safe!");
      }
    });
  };

  // const handleEdit = (_id) => {
  //   localStorage.setItem("application_id", _id);
  //   navigate(`/applicant`);
  // };

  const handleMentor = (id) => {
    navigate(`/select-mentor/${id}`);
  };

  const handleClickOpen = (id) => {
    navigate(`/progress-report/${id}`);
  };
  const [recognitionCertificate] =
    api.endpoints.generateRecognitionCertificate.useLazyQuery();
  const [url, setUrl] = React.useState("");

  const handleCertificate = async (id) => {
    try {
      setLoading(true);
      const { data } = await recognitionCertificate(id);
      if (data?.success) {
        generateCertificate(data?.url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const getActionsWrapper = ({ params = null, role = null, loading }) => {
    const actions = [];

    const LogsButton = (
      <Button
        onClick={() => navigate(`/applicant/logs/${params?.id}`,{
          state: { type: 'recogination' },
        })}
        title="View Logs"
        variant="contained"
        size="small"
        color="inherit"
      >
        < RestoreIcon  />&nbsp; View Logs
      </Button>
    );
    if (role === "user") {
      actions.push(
        // <GridActionsCellItem
        //   icon={<VisibilityIcon />}
        //   onClick={() => navigate(`/recognition/preview/${params?.id}`)}
        //   label="View"
        // />,
        <Button
          onClick={() => navigate(`/recognition/preview/${params?.id}`)}
          title="View Recognition"
          variant="contained"
          size="small"
        >
          {<Visibility />} &nbsp; View Recognition
        </Button>,
        LogsButton
      );
    }
    if (role === "user" && params?.row?.directoratestatus === "approved") {
      actions.push(
        <LoadingButton
          variant="contained"
          onClick={() => handleCertificate(params?.row?._id)}
          disabled={loading}
          startIcon={<ArticleIcon />}
          loading={loading}
          size="small"
        >
          Recognition Certificate
        </LoadingButton>,
        // LogsButton
      );
    } else if (
      role === "superadmin" ||
      role === "admin" ||
      role === "hod" 
    ) {
      actions.push(
        <Button
          onClick={() => handleDelete(params.row._id)}
          title="Delete"
          variant="contained"
          size="small"
          color="error"
        >
          {<Delete />}&nbsp; Delete
        </Button>,
        <Button
          onClick={() => navigate(`/recognition/preview/${params?.id}`)}
          title="View Recognition"
          variant="contained"
          size="small"
        >
          {<Visibility />}&nbsp; View Recognition
        </Button>,
        LogsButton
      );
    }else if(role === "dic"){
      actions.push(<Button
      onClick={() => navigate(`/recognition/preview/${params?.id}`)}
      title="View Recognition"
      variant="contained"
      size="small"
    >
      {<Visibility />}&nbsp; View Recognition
    </Button>,
    LogsButton)
    }

    return actions;
  };

  const columns = useMemo(
    () => [
      {
        field: "founder_name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Mobile Number",
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        
        type: "actions",
        flex: 2.5,
        renderCell: (params)=><Grid
                container
                sx={{
                  overflowX: 'auto',
                  gap: '8px', 
                  
                }}
              >
                {getActionsWrapper({ params, role, loading })}
              </Grid>
        // getActions: (params) => getActionsWrapper({ params, role, loading }),
      },
    ],
    [role, loading]
  );

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: newPageSize.pageSize }));
  }, []);

  const handlePaginationModelChange = useCallback((data) => {
    setPaginationModel(data);
  }, []);
  const handlePdf = async () => {
    const headers = [
      { title: "Aadhaar", id: "aadhaar" },
      { title: "Application Stage", id: "application_stage" },
      { title: "Business Address", id: "business_address" },
      { title: "Category", id: "category" },
      { title: "Co founders", id: "co_founders" },
      { title: "Contact", id: "contact" },
      { title: "Email", id: "email" },
      { title: "Revenue", id: "revenue" },
      { title: "Gst No.", id: "gst_no" },
      { title: "Uniqueness", id: "uniqueness" },
      // { title: "No. of Employees", id: "number_of_employees" },
      // { title: "No. of Female Employees", id: "number_of_female_employees" },
      // { title: "No. of Himachali Employees", id: "number_of_himachali_employees" },
    ];

    const doc = new jsPDF({
      orientation: "landscape",
      // unit: "in",
      // format: [8, 6]
    });

    try {
      // const { data } = await singleIncuba(id);
      const recognitionData = recognitionList?.data?.AllApplication || [];

      const tableData = recognitionData.map((item) => {
        return headers.reduce((acc, header) => {
          acc[header.id] = item[header.id] || "";
          return acc;
        }, {});
      });

      doc.autoTable({
        head: [headers.map((header) => header.title)],
        body: tableData.map((item) => headers.map((header) => item[header.id])),
        margin: { top: 10 },
        styles: { fontSize: 10 },
      });

      doc.save("recognition_list.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      <Paper className="paper-card">
        <Grid container>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <Typography variant="h5">Recognition List</Typography>
          </Grid>
          {recognitionList?.data?.AllApplication.length > 0 ? (
            <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
              <Tooltip title="Download PDF" arrow>
                <IconButton onClick={handlePdf} color="primary">
                  <DownloadIcon sx={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          {role === "dic" ? (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={3}>
              {/* <InputLabel id="language_change" sx={{ color: "#000" }}>
                District :
              </InputLabel> */}
              <FormControl fullWidth>
                <InputLabel id="district">District</InputLabel>
                <Select
                  margin="dense"
                  fullWidth
                  id="district"
                  label="District"
                  name="district"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  // onBlur={formik.handleBlur}
                  // error={
                  //   formik.touched.district && Boolean(formik.errors.district)
                  // }
                  // helperText={formik.touched.district && formik.errors.district}
                >
                  {districtList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
        </Grid>

        <Box
          marginTop={2}
          sx={{ width: "100%", height: "500px",  }}
        >
            <DataGrid
              columns={columns}
              // autoHeight
              // rowHeight={52} // Adjusted row height
              getRowHeight={() => 80} // Set the row height here
              rowCount={recognitionList?.data?.totalItems || 0}
              getRowId={(row) => row._id}
              pageSizeOptions={[10, 25, 50, 100]}
              loading={isLoading}
              pagination
              paginationModel={paginationModel}
              paginationMode="server"
              onPageSizeChange={handlePageSizeChange}
              onPaginationModelChange={handlePaginationModelChange}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                toolbar: GridToolbar,
              }}
              sx={{
                width: "100%",
                marginBottom: "16px",
                "& .MuiDataGrid-cell": {
                  py: 1, // Adjust padding of cells
                },
              }}
              rows={recognitionList?.data?.AllApplication || []}
            /> 
        </Box>
      </Paper>
    </Box>
  );
};

export default RecognitionIndex;
