import { Box, Button, Chip, Grid, Paper, Switch, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material/";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  api,
  useDeleteStateMutation,
  useGetStateQuery,
  useTrackApplicationLogsQuery,
  useUpdateStateMutation,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { setStateData } from "../../Redux/GlobalSlice";
import useDrawer from "../../Helpers/customiseHook";
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import UpsertState from "../UpsertState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const LogsList = () => {
  const { id  } = useParams();
  const location=useLocation()
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { state, toggleDrawer } = useDrawer();
  const [logData, setLogData] = useState(null);
  const dispatch = useDispatch();
  const [trackApplicationLogs] =
    api.endpoints.trackApplicationLogs.useLazyQuery(); // Get Incubation list
  const [deleteState] = useDeleteStateMutation(); // Get Incubation list
  const [updateState] = useUpdateStateMutation();

  React.useEffect(() => {
    const ApplicationLogs = async () => {
      if (id) {
        const obj={
          id:id,
          type:location?.state?.type
        }
        try {
          const { data } = await trackApplicationLogs(obj);
          if (data?.success) {
            setLogData(data);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    ApplicationLogs();
  }, [id]);

  const handleChange = async (params) => {
    const newStatus =
      params.documentStatus === "active" ? "inactive" : "active";
    const res = await updateState({
      id: params?._id,
      payload: { documentStatus: newStatus },
    });
    if (res.data.success) {
      toast.success("Status is updated Successfully.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (res?.error) {
      toast.error(res?.error?.data?.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the State!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteState(id);
        swal("State deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("State is safe!");
      }
    });
  };
  const handleNavigate=(params)=>{
    if(location?.state?.type==="application"){
      navigate(`/log/${params.row._id}`)
    }else if(location?.state?.type==="pr"){
      navigate(`/prlog/${params.row._id}`)
    }else if(location?.state?.type==="mentor"){
      navigate(`/mentorlog/${params.row._id}`)
    }
    else if(location?.state?.type==="recogination"){
      navigate(`/recolog/${params.row._id}`)
    }
  }

  const columns = useMemo(() => {
    return [
      {
        field: "created",
        headerName: "Action By",
        flex: 1,
        valueGetter: (params) => params.row.createdby?.name || "N/A", // Assuming `name` is the property you want to show
      },
      {
        field: "createdby",
        headerName: "Email",
        flex: 1,
        valueGetter: (params) => params.row.createdby?.email || "N/A", // Assuming `name` is the property you want to show
      },
      {
        field: "createdAt",
        headerName: "Date",
        flex: 1,
        renderCell: (params) => {
          return(
          <Typography>
            {moment(params?.row?.createdAt).format("DD-MM-YYYY")}
          </Typography>
        )},
      },
      {
        field: "roles",
        headerName: "Role",
        flex: 1,
        renderCell: (params) => {
        
         return params?.row?.createdby?.roles.map((role) => (
            <Typography>{role?.name}</Typography>
          ));
        
        },
      },
      // {
      //   field: "image",
      //   headerName: "Profile Picture",
      //   flex: 1,
      //   renderCell: (params) =>
      //     <Box
      //   sx={{
      //     width: "50px", // Set the desired width
      //     height: "50px", // Set the desired height
      //     overflow: "hidden", // Ensures image stays within bounds
      //     display: "flex", // Centers the image
      //     justifyContent: "center",
      //     alignItems: "center",
      //     border: "1px solid #ddd", // Optional: adds a border
      //     borderRadius: "5px", // Optional: adds rounded corners
      //   }}
      // >
      //   <img
      //     src={params?.row?.image}
      //     alt="dp"
      //     style={{
      //       width: "100%",
      //       height: "100%",
      //       objectFit: "cover", // Ensures the image fills the box proportionally
      //     }}
      //   />
      // </Box>
      // },
       {
              field: "documentStatus",
              headerName: "Status",
              flex: 1,
              renderCell: (params) => (
                <Chip
                label={params?.row?.createdby?.documentStatus === "active"?"active":"inactive"}
                sx={{ backgroundColor: "lightgrey" }} // Set background color based on role name
              />
                // <Switch
                //   value={params?.row?.createdby?._id}
                //   checked={params?.row?.createdby?.documentStatus === "active"}
                //   // onChange={() => {
                //   //   handleChange(params.row);
                //   // }}
                //   inputProps={{ "aria-label": "controlled" }}
                // />
              ),
            },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        getActions: (params) => [
          <Button
            onClick={() => handleNavigate(params)}
            title="Edit"
            variant="contained"
            size="small"
            color="success"
          >
            <Visibility /> &nbsp; View Log
          </Button>,
        ],
        flex: 1,
        width: "50%",
      },
    ];
  }, []);
  const handlePageSizeChange = useCallback(
    (data) => {
      setPaginationModel(data);
    },
    [setPaginationModel]
  );

  return (
    <Box marginTop={"6em"} padding={4}>
      {/* Inner container */}
      <ToastContainer />
      <Paper className="paper-card">
        {/* Header */}
        <Grid container>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <Typography variant="h5">
          {    `${location?.state?.type==="pr"?"Progress Report":location?.state?.type==="mentor"?"Mentor":location?.state?.type==="application"?"Application":"Recognition"} Logs List`}

            </Typography>
          </Grid>
          {/* <Grid className="button-right" item lg={3} md={3} sm={3} xs={3}>
            <Button
              variant="contained"
              onClick={(e) => {
                toggleDrawer("right", true)(e);
                setShowCase("add");
              }}
              startIcon={<Add />}
            >
              <Typography color="white" variant="body1">
                Add new
              </Typography>
            </Button>
          </Grid> */}
        </Grid>

        {/* Table */}
        <Box
          marginTop={2}
          sx={{ width: "100%", height: "500px", overflowX: "auto" }}
        >
          <DataGrid
            columns={columns}
            autoHeight
            getRowId={(row) => row._id}
            rowCount={logData?.total || 0}
            getRowHeight={() => 60}
            pageSizeOptions={[10, 25, 50, 100]}
            // loading={isLoading}
            pagination
            columnBuffer={columns.length + 1}
            rowBuffer={10}
            paginationModel={paginationModel}
            paginationMode="server"
            onPageSizeChange={(newPageSize) =>
              handlePageSizeChange(newPageSize)
            }
            onPaginationModelChange={(data) => handlePageSizeChange(data)}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              toolbar: GridToolbar,
            }}
            sx={{
              "--DataGrid-overlayHeight": "100%",
              width: "100%",
              marginBottom: "16px",
            }}
            rows={logData?.data || []}
          />
        </Box>
      </Paper>
      {/* <UpsertState {...{ state, toggleDrawer, showCase }} /> */}
    </Box>
  );
};

export default LogsList;
