import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { api } from "../../services/api";
import { Grid, Typography } from "@mui/material";
import moment from "moment";

export default function TrackMentorApplication({ id }) {
  const [trackMentorApplication] =
    api.endpoints.trackMentorStatus.useLazyQuery();
  const [status, setStatus] = React.useState();
  const [userData, setUserData] = React.useState();
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackMentorApplication(id);
          if (data?.success) {
            setStatus(data?.application?.hodstatus);
            setUserData(data?.application);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector
            sx={{
              backgroundColor:
                status === "approved"
                  ? "green"
                  : status === "rejected"
                  ? "red"
                  : "grey",
            }}
          />
          <TimelineDot
            color={
              status === "approved"
                ? "success"
                : status === "rejected"
                ? "error"
                : "grey"
            }
          />
        </TimelineSeparator>
        <TimelineContent>
          HOD/Directorate Status
          {userData?.hoduser?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.hoduser?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.remark !== null && userData?.remark !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.remark}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.hodstatusdate !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.hodstatusdate).format("LL")}
            </Typography>
          ) : (
            ""
          )}
          {/* </Grid> */}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
