import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Container,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  OutlinedInput,
  useTheme,
} from "@mui/material";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  useCreateUserMutation,
  useIncubationDetailsQuery,
  useGetSingleIncubationQuery,
  useUpdateIncubationUserMutation,
  useRolesDetailsQuery,
  useGetDistrictQuery,
} from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, ErrorOutlineOutlined } from "@mui/icons-material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import dayjs from "dayjs";
// import "./style.scss"
import LoadingButton from "@mui/lab/LoadingButton";
import PublishIcon from "@mui/icons-material/Publish";

const atLeastMinimumLength = (password) =>
  new RegExp(/(?=.{8,})/).test(password);
const atLeastOneUppercaseLetter = (password) =>
  new RegExp(/(?=.*?[A-Z])/).test(password);
const atLeastOneLowercaseLetter = (password) =>
  new RegExp(/(?=.*?[a-z])/).test(password);
const atLeastOneNumber = (password) =>
  new RegExp(/(?=.*?[0-9])/).test(password);
const atLeastOneSpecialChar = (password) =>
  new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(password);

const PasswordStrength = {
  WEAK: "weak",
  MEDIUM: "medium",
  STRONG: "strong",
};

const checkPasswordStrength = (password) => {
  if (!password) return PasswordStrength.WEAK;
  let points = 0;
  if (atLeastMinimumLength(password)) points += 1;
  if (atLeastOneUppercaseLetter(password)) points += 1;
  if (atLeastOneLowercaseLetter(password)) points += 1;
  if (atLeastOneNumber(password)) points += 1;
  if (atLeastOneSpecialChar(password)) points += 1;
  if (points >= 5) return PasswordStrength.STRONG;
  if (points >= 3) return PasswordStrength.MEDIUM;
  return PasswordStrength.WEAK;
};
const getIcon = (passwordStrength) => {
  let icon = ErrorOutlineOutlined;

  switch (passwordStrength) {
    case passwordStrength.WEAK:
      icon = ErrorOutlineOutlined;
      break;
    case passwordStrength.MEDIUM:
      icon = ErrorOutlineOutlined;
      break;
    case PasswordStrength.STRONG:
      icon = CheckOutlined;
      break;
    default:
      icon = ErrorOutlineOutlined;
  }
  return icon;
};

const generateColors = (passwordStrength) => {
  let result = [];
  const COLORS = {
    NEUTRAL: "hsla(0, 0%, 88%,1)",
    WEAK: "hsla(353, 100%, 38%, 1)",
    MEDIUM: "hsla(40, 71%, 51%, 1)",
    STRONG: "hsla(134, 73%, 30%, 1)",
  };

  switch (passwordStrength) {
    case PasswordStrength.WEAK:
      result = [COLORS.WEAK, COLORS.NEUTRAL, COLORS.NEUTRAL, COLORS.NEUTRAL];
      break;

    case PasswordStrength.MEDIUM:
      result = [COLORS.MEDIUM, COLORS.MEDIUM, COLORS.NEUTRAL, COLORS.NEUTRAL];
      break;
    case PasswordStrength.STRONG:
      result = [COLORS.STRONG, COLORS.STRONG, COLORS.STRONG, COLORS.STRONG];
      break;
    default:
      result = [];
      break;
  }
  return result;
};

const ValidatePasswordStrength = (props) => {
  const { isStrong, setGetPassword } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const passwordStrength = checkPasswordStrength(password);
  const Icon = getIcon(passwordStrength);
  const colors = generateColors(passwordStrength);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  if (passwordStrength === "strong") {
    isStrong(true);
    setGetPassword(password);
  } else {
    isStrong(false);
  }

  return (
    <>
      <FormControl margin="dense" fullWidth required={true}>
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          onChange={(e) => setPassword(e.target.value)}
          id="password"
          margin="dense"
          name="password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>

      {password.length > 0 && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            margin="10px 0"
          >
            {colors.map((color, index) => (
              <Box
                key={index}
                flex={1}
                height="5px"
                borderRadius="5px"
                bgcolor={color}
              ></Box>
            ))}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="5px"
            margin="0 0 15px 0"
          >
            <Icon htmlColor={colors[0]} />
            <Typography color={colors[0]}>{passwordStrength}</Typography>
          </Box>
        </>
      )}
      {passwordStrength !== PasswordStrength.STRONG && (
        <>
          <Typography
            variant="subtitle2"
            fontSize="1rem"
            color="text.headingLight"
            margin="0 0 8px 0"
          >
            Password should be of 8 character
          </Typography>
          <Typography
            variant="subtitle2"
            fontSize="14px"
            fontWeight={500}
            color="text.bodyLight"
            margin="0 0 24px 0px"
          >
            Password should be incluing one special charater, lower case,
            uppercase and number
          </Typography>
        </>
      )}
    </>
  );
};

const IncubationUser = () => {
  const { id } = useParams();

  const validationSchema = yup.object({
    // incubation: id
    //   ? yup.string()
    //   : yup.string().required("Please select register type"),
    roles: yup
      .array()
      .of(yup.string().required("Role is required"))
      .min(1, "At least one role is required"),
    name: yup
      .string()
      .required("Please enter your name")
      .matches(/^[A-Za-z]+$/, "Name can only contain letters and no spaces"),
    gender: yup.string().required("Please select your gender"),
    number: yup
      .string("Please enter valid number")
      .matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number")
      .required("Please enter your mobile number"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: id
      ? yup.string("Enter your password")
      : yup
          .string("Enter your password")
          .min(8, "Password should be of minimum 8 characters length")
          .required("Password is required")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          ),
    Cpassword: id
      ? yup.string("Enter your password")
      : yup
          .string("Enter your password")
          .required("Confirm Password Required")
          .oneOf([yup.ref("password"), null], "Passwords must match"),
    district: yup.string("district is required"),
  });
  const [userRoleID, setUserRoleID] = useState([]);
  const [dob, setDob] = useState("");
  // const [captcha, setCaptcha] = useState("");
  // const { data, error, isLoading } = useGetAllRegisterTypesQuery();
  const {
    data: incubationList,
    isLoading,
    isError,
  } = useIncubationDetailsQuery();
  const { data: SingleDetail } = useGetSingleIncubationQuery(id);
  const { data: rolesList } = useRolesDetailsQuery();
  const [incubationUpdate] = useUpdateIncubationUserMutation();
  const [createUser] = useCreateUserMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: districtList } = useGetDistrictQuery();

  const navigate = useNavigate();
  const theme = useTheme();
  // const {
  //   data: incubationList,
  //   error: roleError,
  //   isLoading: roleIsLoadng,
  // } = useGetAllRolesQuery("user");

  // useEffect(() => {
  //   if (singleRec) formik.setValues((prev)=>({
  //     ...prev,
  //    roles: singleRec?.data[0]?._id,
  //   }))
  // }, [singleRec]);

  const formik = useFormik({
    initialValues: {
      incubation: "",
      roles: [],
      name: "",
      gender: "",
      number: "",
      email: "",
      password: "",
      Cpassword: "",
      district: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          setLoading(true);
          //   {
          //     "password": "Login@123",
          //     "roles":["65e078fc4ce2219623e4d42b"],
          //     "name":"user1",
          //     "mobile":"9736511416",
          //     "dob":"1992-06-23",
          //     "gender":"male",
          //     "incubation_centre":"6594163160a4c756917e8546"
          // }
          const payload = {
            id: SingleDetail?.data?._id,
            payload: {
              name: values.name,
              gender: values.gender,
              // incubation_centre: values.incubation,
              mobile: values.number,
              password: values.password,
              roles: values?.roles,
              dob: dob,
              district: values?.district,
              // roles:roles
            },
          };
          const {
            data: updatedData,
            error: isCreateError,
            isLoading: isUserLoading,
          } = await incubationUpdate(payload);
          if (updatedData?.success) {
            let time = 2000;
            toast.success("User Updated successfully", {
              position: "top-center",
              autoClose: time,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              navigate("/incubation-user");
            }, time + 1000);
          }
        } else {
          setLoading(true);
          const getData = {
            email: values.email,
            name: values.name.trim(),
            gender: values.gender,
            // incubation_centre: values.incubation,
            mobile: values.number,
            password: values.password,
            roles: values?.roles,
            dob: dob,
            district: values?.district,
            // roles:roles
          };
          const {
            data: userData,
            error: isCreateError,
            isLoading: isUserLoading,
          } = await createUser(getData);

          if (userData && userData?.success === true) {
            const time = 2000;
            toast.success("User Created successfully", {
              position: "top-center",
              autoClose: time,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            setTimeout(() => {
              navigate("/incubation-user");
            }, time + 1000);
          }

          if (isCreateError?.data && !isUserLoading) {
            setLoading(false);
            toast.error(isCreateError.data.message ?? "Something went wrong", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("An error occurred:", error);
        // Handle error as needed, for example:
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  React.useEffect(() => {
    if (SingleDetail?.data) {
      const { data } = SingleDetail;
      formik.setValues((prevValues) => ({
        ...prevValues,
        email: data?.email,
        name: data?.name,
        gender: data?.gender,
        incubation: data?.incubation_centre,
        number: data?.mobile,
        // password: data?.password,
        // roles: userRoleID,
        district: data?.district,
        roles: data?.roles,
      }));

      setDob(dayjs(moment(data?.dob).format("YYYY-MM-DD")));
    }
  }, [id, SingleDetail?.data]);

  return (
    <Box marginTop={"6em"} padding={4}>
      <ToastContainer />
      {/* <Banner /> */}
      <Paper className="paper-card">
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h5">{`User ${
              id ? "Edit" : "Add"
            }`}</Typography>
          </Grid>
          <Grid mt={5} container spacing={2}>
            {/* incubation Type */}
            {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <InputLabel id="language_change" sx={{ color: "#000" }}>
                Incubation Type
              </InputLabel>
              <Select
                fullWidth
                value={formik.values.incubation}
                onChange={(e) =>
                  formik.setFieldValue("incubation", e.target.value)
                }
                labelId="incubation-label"
                id="incubation"
                name="incubation"
                label="Incubation Type"
                autoComplete="off"
                placeholder="Select Incubation"
              >
                {!isLoading &&
                  incubationList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item?.host_institute_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid> */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {/* <InputLabel id="district" >
                District :
              </InputLabel> */}
              <FormControl fullWidth>
                <InputLabel id="district">District</InputLabel>
                <Select
                  margin="dense"
                  fullWidth
                  id="district"
                  label="District"
                  name="district"
                  value={formik.values.district}
                  onChange={(e) =>
                    formik.setFieldValue("district", e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.district && Boolean(formik.errors.district)
                  }
                  helperText={formik.touched.district && formik.errors.district}
                >
                  {districtList?.data?.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="roles">Roles</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={formik.values.roles}
                  onChange={(e) =>
                    formik.setFieldValue("roles", e.target.value)
                  }
                  labelId="roles-label"
                  id="roles"
                  name="roles"
                  label="Roles"
                  autoComplete="off"
                  placeholder="Select roles"
                >
                  {!isLoading &&
                    rolesList?.data?.map((item) => (
                      <MenuItem value={item._id} key={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.touched.roles && formik.errors.roles ? (
                <Typography color="error" fontSize={13}>
                  {formik.errors.roles}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} mt={0.5}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                placeholder="Name"
                autoComplete="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>

            {/* Date of birth */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <DatePicker
                disableFuture
                sx={{
                  width: "100%",
                  marginTop: "1em",
                  marginBottom: "0.5em",
                }}
                name="dob"
                label="Date of birth"
                value={dob}
                margin="dense"
                onChange={(value) => setDob(value["$d"])}
              />
            </Grid>

            {/* Gender */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="gender"
                label="Gender"
                name="gender"
                select
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
                // onChange={(e) => handleChange(e)}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="transgender">Transgender</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>

            {/* Mobile Number */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                id="mobile-number"
                label="Mobile Number"
                name="number"
                autoComplete="number"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
                // onChange={(e) => handleChange(e)}
              />
            </Grid>

            {/* Email */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                margin="dense"
                disabled={id ? true : false}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                autoComplete="email"
                // onChange={(e) => handleChange(e)}
              />
            </Grid>
            {/* Password */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <TextField
                margin="dense"
                required
                fullWidth
                name="password"
                label={id ? "Update Password" : "Password"}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <ValidatePasswordStrength setGetPassword={setGetPassword} isStrong={setisSubmitEnable} /> */}
            </Grid>
            {/* Confirm Password */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {!id ? (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  name="Cpassword"
                  label="Confirm Password"
                  type="password"
                  id="confirm-password"
                  autoComplete="confirm-password"
                  value={formik.values.Cpassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Cpassword && Boolean(formik.errors.Cpassword)
                  }
                  helperText={
                    formik.touched.Cpassword && formik.errors.Cpassword
                  }
                  // onChange={(e) => handleChange(e)}
                />
              ) : null}
            </Grid>
            {/* Verifcation code */}
            {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="captcha-continer">
                    <LoadCanvasTemplate
                      reloadText="Get a new code"
                      reloadColor="white"
                    />
                    <div className="input-block">
                      <TextField
                        margin="dense"
                        required
                        fullWidth
                        name="captcha"
                        type="text"
                        id="captcha"
                        placeholder="Enter Verification code"
                        onChange={(e) => setCaptcha(e.target.value)}
                      />
                    </div>
                  </div>
                </Grid> */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {/* <Box ml={2} display="inline-block"> */}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  endIcon={<PublishIcon />}
                  loading={loading}
                  loadingPosition="end"
                >
                  <span>{id ? "Update" : "Submit"}</span>
                </LoadingButton>
              {/* </Box> */}
              {/* <Button
                type="submit"
                disabled={loading}
                variant="contained"
                size="medium"
              >
                {id ? "Update" : "Submit"}
              </Button> */}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default IncubationUser;
