import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  startup_name: Yup.string().required("Startup name is required"),
  founder_name: Yup.string().required("Founder name is required"),
  women_led_startup: Yup.string()
    .oneOf(["yes", "no"], 'Women led startup must be "yes" or "no"')
    .required("Women led startup is required"),
  category: Yup.string().required("Category is required"),
  incubation_center: Yup.string().required("Incubation center is required"),
  incubationStartDate: Yup.date().required("Incubation start date is required"),
  mentor_name: Yup.string().required("Mentor name is required"),
  address: Yup.string().required("Address is required"),
  district: Yup.string().required("District is required"),
  mobile_number: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("Mobile number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  // state_startup_registration_number: Yup.string().required(
  //   "State startup registration number is required"
  // ),
  // incorporation_number: Yup.string().required(
  //   "Incorporation number is required"
  // ),
  task_completed: Yup.string().required("Task completed is required"),
  prev_report_num: Yup.number()
    .min(0, "Previous report number must be a positive number")
    .required("Previous report number is required"),
  summary: Yup.string().required("Summary is required"),
  current_stage: Yup.string().required("Current stage is required"),
  turn_over: Yup.number()
    .min(0, "Turn over must be a positive number")
    .required("Turn over is required"),
  turn_over_proof: Yup.string().required("Turn over proof is required"),
  investment_funding: Yup.string().required("Investment funding is required"),
  //   events_attended: Yup.string().required('Events attended is required'),
  // industry_tie_ups: Yup.string().required('Industry tie ups are required'), // Uncomment if needed
  //   documents: Yup.string().required('Documents are required'),
  turn_over_name: Yup.string(),
  upload_doc_name: Yup.string(),
  investment_funding_name: Yup.string(),
  fieldsCount: Yup.number()
    .min(1, "Fields count must be at least 1")
    .required("Fields count is required"),
  custom_fields: Yup.array()
    .of(
      Yup.object().shape({
        event: Yup.string(),
        date: Yup.date(),
        details: Yup.string().nullable(), // Allows null values
      })
    )
    .min(1, "At least one custom field is required"),
  industryCount: Yup.number(),
  industry_custom_fields: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        email: Yup.string(),
        office_address: Yup.string(),
        contact: Yup.number(),
      })
    )
    .min(1, "At least one industry custom field is required"),
  upload_doc: Yup.string().required("Upload doc is required"),
  //   comment_by_mentor: Yup.string().required('Comment by mentor is required'),
  //   comment_by_nodal_officer: Yup.string().required('Comment by nodal officer is required'),
  //   comment_by_gm: Yup.string().required('Comment by GM is required'),
  //   comment_by_directorate_of_industries: Yup.string().required('Comment by Directorate of Industries is required')
});

export default validationSchema;
