import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, Paper, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  api,
  useLoginUserMutation,
  useLoginUserOtpMutation,
  usePostOTPMutation,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { setLoginDetails, setSideBarContent } from "../../Redux/GlobalSlice";
import smallLogo from "../../Assests/hp_icon.png";
import black_bg from "../../Assests/Event-bg.png";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { useTranslation } from "react-i18next";

const validateSchema = yup.object({
  mobile: yup.string("Enter your mobile"),
  otp: yup.string("Enter your otp"),
  //   password: yup
  //     .string("Please enter password")
  //     .required("Password is required"),
});

export const LoginWithOTP = () => {
  const [value, setValue] = useState("1"); // State to manage active tab
  const [showPassword, setShowPassword] = useState(false);
  const [showOTPField, setShowOTPField] = useState(false);
  // const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [userData] = api.endpoints.applicationDetail.useLazyQuery();
  const [loginUserOtp] = useLoginUserOtpMutation();
  const [postMob] = usePostOTPMutation();
  const [captcha, setCaptcha] = useState("");
  const theme=useTheme()
  const { t } = useTranslation();

  // const {data:routeList}= useMenuDetailsQuery();
  const [routeList] = api.endpoints.menuDetails.useLazyQuery();
  const handleGetOTP = async () => {
    if (formik.values.mobile && !formik.values.otp) {
      // Send mobile number to backend to request OTP
      const response = await postMob({ mobile: formik.values.mobile });
      if (response?.data?.success) {
        // OTP sent successfully, show OTP input field
        setShowOTPField(true);
        // Optionally, you can display a message to the user indicating that the OTP has been sent
        toast.success(response?.data?.data, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Handle error if OTP sending failed
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (formik.values.otp && formik.values.mobile) {
      if (!validateCaptcha(captcha) == true) {
        toast.error("Captcha did not match", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadCaptchaEnginge(6);
        return;
      }
      const response = await loginUserOtp({
        otp: formik.values.otp,
        mobile: formik.values.mobile,
      });
      toast.success(response?.data?.success, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (response?.data?.success) {
        dispatch(setLoginDetails(response?.data?.data));
        localStorage.setItem("role", response?.data?.data?.role[0]?.name);
        localStorage.setItem("user_id", response?.data?.data?.id);
        const token = response?.data?.data?.token;
        if (!localStorage.getItem("personal_details")) {
          const response = await userData(token);
          if (response?.data?.success) {
            localStorage.setItem(
              "userData",
              JSON.stringify(response?.data?.data)
            );
          }
        }
        if (!localStorage.getItem("token")) {
          localStorage.setItem("token", token);
          const res = await routeList(token);
          const { data } = await res?.data?.total;
          dispatch(setSideBarContent(data));
          navigate("/dashboard");
        } else if (localStorage.getItem("token")) {
          await routeList(token);
          navigate("/dashboard");
        }
      } else if (response?.error) {
        toast.error(response?.error?.data?.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      // This block handles the scenario where neither the mobile number nor the OTP is provided
      console.error("Mobile number and OTP are required");
    }
  };

  const formik = useFormik({
    initialValues: {
      //   email: "",
      //   password: "",
      mobile: "",
      otp: "", // Added OTP field
    },
    validationSchema: validateSchema,

    onSubmit: async (values) => {
      const {
        data: loginData,
        error,
        isLoading,
      } = await loginUser({ ...values });
      if (!validateCaptcha(captcha) == true) {
        toast.error("Captcha did not match", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        loadCaptchaEnginge(6);
        return;
      }
      if (error) {
        toast.error(error.data?.message ?? "Something went wrong", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (loginData?.success) {
        dispatch(setLoginDetails(loginData?.data));
        localStorage.setItem("role", loginData?.data?.role[0]?.name);
        const token = loginData.data.token;
        if (!localStorage.getItem("personal_details")) {
          const response = await userData(token);
          if (response?.data?.success) {
            localStorage.setItem(
              "userData",
              JSON.stringify(response?.data?.data)
            );
          }
        }
        if (!localStorage.getItem("token")) {
          localStorage.setItem("token", token);
          const res = await routeList(token);
          const { data } = res?.data?.total;
          dispatch(setSideBarContent(data));
          navigate("/dashboard");
        } else if (localStorage.getItem("token")) {
          const response = await routeList(token);
          navigate("/dashboard");
        }
      }
    },
  });
  const handleLoginWithUserIdPassword = () => {
    navigate("/sign-in");
  };
  useEffect(() => {
    // setTimeout(()=>{
    loadCaptchaEnginge(6, "grey");
    // },100)
  }, [value]);
  return (
    <Box
      component="main"
      maxWidth="xxl"
      sx={{
        display: "flex",
        flexDirection: "row",
        background: `url(${theme.palette.mode==="dark"?null:black_bg}) center center no-repeat`, // Center the background image
        backgroundSize: "cover",
        width: "100%",
        height: "80%",
        justifyContent: "space-around",
        backgroundColor: (theme) =>
          theme.palette.background.default,
        color: (theme) => theme.palette.text.primary
      }}
    >
      <Box>
      <Container component="main" className="login-page d-flex h-100 justify-content-center align-items-center" maxWidth="xs">
          <CssBaseline />
          <ToastContainer />

          <Paper
            sx={{
              padding: "2em 2em",
              borderRadius: "3em",
              marginBottom: "4em",
              marginTop: "3em",
            }}
            elevation={5}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={smallLogo} alt="" />
              <br/>
              <Typography variant="h5" align="center" fontWeight="600">
              { t("Sign In")}
              </Typography>

              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile Number"
                  name="mobile"
                  autoComplete="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                  autoFocus
                />

                {showOTPField && ( // Conditionally render OTP field
                  <TextField
                    margin="dense"
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    type="text"
                    id="otp"
                    autoComplete="otp"
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.otp && Boolean(formik.errors.otp)}
                    helperText={formik.touched.otp && formik.errors.otp}
                  />
                )}
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box className="captcha-continer">
                    <LoadCanvasTemplate
                      reloadText="Get a new code"
                      reloadColor="white"
                    />
                    <Box className="input-block">
                      <TextField
                        margin="dense"
                        required
                        fullWidth
                        name="captcha"
                        type="text"
                        id="captcha"
                        value={captcha}
                        placeholder="Enter Verification code"
                        onChange={(e) => setCaptcha(e.target.value)}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={handleGetOTP} // Call handleGetOTP function instead of form submission directly
                >
                  {showOTPField ? "Sign In" : "Get OTP"}{" "}
                </Button>
                <Grid className="or-text">
                      OR
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={handleLoginWithUserIdPassword} // Call handleLoginWithUserIdPassword function instead of form submission directly
                >
                  Login with USERID AND PASSWORD
                </Button>

                {/* <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button> */}
                <Grid container textAlign={"center"}>
                  <Grid item xs>
                    <Link
                      to="/"
                      variant="body2"
                      style={{ textDecoration: "none" }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item textAlign={"center"} width={"100%"}>
                    <Link
                      to="/sign-up"
                      variant="body2"
                      style={{
                        textDecoration: "none",
                        textAlign: "center",
                      }}
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};
