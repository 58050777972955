import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
// import { api, useCreateApplicantMutation, useSingleApplicantQuery, useUploadDocMutation } from "../../services/api";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  api,
  useCreateApplicantMutation,
  useGetBETQuery,
  useGetBlockQuery,
  useGetDistrictQuery,
  useGetLegislativeQuery,
  useGetParliamentDistrictQuery,
  useGetPCQuery,
  useGetStateQuery,
  useGetTehsilQuery,
} from "../../services/api";
import * as Yup from "yup";

const BusinessDetails = ({ steps, active, setActive }) => {
  const schema = Yup.object().shape({
    bet: Yup.string().required("Business Entity Type is required"),
    lac: Yup.string().nullable(),
    pc: Yup.string().nullable(),
    state: Yup.string().nullable(),
    district: Yup.string().required("District is required"),
    tehsil: Yup.string().nullable(),
    block: Yup.string().nullable(),
    pin: Yup.string().nullable(),
    gst_no: Yup.string()
      .nullable()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
        "Invalid GST number format"
      ),
    incorporation_number: Yup.string().nullable(),
    udyam_no: Yup.string().nullable(),
    business_address: Yup.string().nullable(),
  });
  // const [createApplicant] = useCreateApplicantMutation();
  // const [fileUpload] = useUploadDocMutation();
  const _id = localStorage.getItem("application_id");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  // const {
  //   data: getApplicant,

  // } = useSingleApplicantQuery(_id);
  // const [getAllApplicant] =
  // api.endpoints.singleApplicant.useLazyQuery();

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([
    {
      document: "Proposal For Presentation",
      type: "Pdf/Word/Ppt",
      name: "",
      file: null,
    },
  ]);
  const { data: betList } = useGetBETQuery();
  const { data: lacList, isLoading, isError } = useGetLegislativeQuery();
  const { data: pcList } = useGetPCQuery();
  const { data: stateList } = useGetStateQuery(); // Get Incubation list
  const { data: districtList } = useGetDistrictQuery();
  const { data: tehsilList } = useGetTehsilQuery();
  const { data: blockList } = useGetBlockQuery();
  const [newApplicant] = useCreateApplicantMutation();
  const [getAllApplicant] = api.endpoints.singleApplicant.useLazyQuery();
  const [contiTencyData] = api.endpoints.getParlimentContiWise.useLazyQuery();
  const [lacData] = api.endpoints.getLegislativeDistrictWise.useLazyQuery();
  const [tehsilDataDistrictWise] =
    api.endpoints.getTehsilDistrictWise.useLazyQuery();

  const formik = useFormik({
    initialValues: {
      bet: "",
      lac: "",
      // pc: "",
      // pList:[],
      parliment: "",
      constituency: "",
      // pList:[],
      constiList: [],
      lacList: [],
      blockList: [],
      tehsilList: [],
      state: "",
      district: "",
      tehsil: "",
      block: "",
      pin: "",
      gst_no: "",
      incorporation_number: "",
      udyam_no: "",
      business_address: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        let data = {
          step: "business",
          payload: {
            business_entity_type: values.bet,
            business_address: values.business_address,
            business_lac: values.lac,
            business_parliament: values.parliment,
            // business_constituency: values.constituency,
            business_state: values.state,
            business_district: values.district,
            business_teshil: values.tehsil,
            business_block: values.block,
            business_pin: values.pin,
            gst_no: values.gst_no,
            incorporation_number: values.incorporation_number,
            udyam_no: values.udyam_no,
            application_id: _id,
          },
        };
        const response = await newApplicant(data);
        if (response?.data?.success) {
          toast.success("Business Details Created Sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    },
  });

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };
  const handleNext = (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  // const handleFileUpload = (index, event) => {
  //   setRows([{
  //     document: "Proposal For Presentation",
  //     type: "Pdf/Word/Ppt",
  //     name: event.target.files[0].name,
  //     file: null,
  //   }])
  //   formik.setFieldValue("document", event.target.files[0]);
  // };
  const fileInputRef = useRef(null);

  const handleIconClick = (index) => {
    fileInputRef.current && fileInputRef.current.click();
  };
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (_id) {
        try {
          const { data } = await getAllApplicant(_id);
          const {
            business_entity_type,
            business_address,
            business_lac,
            business_parliament,
            business_constituency,
            business_state,
            business_district,
            business_teshil,
            business_block,
            business_pin,
            gst_no,
            incorporation_number,
            udyam_no,
          } = data?.application;

          formik.setValues((prev) => ({
            ...prev,
            bet: business_entity_type,
            lac: business_lac,
            // pc: business_parliament_constituency,
            parliment: business_parliament,
            // constituency:business_constituency,
            state: business_state,
            district: business_district,
            tehsil: business_teshil,
            block: business_block,
            pin: business_pin,
            gst_no,
            incorporation_number,
            udyam_no,
            business_address: business_address,
          }));
          if (business_district) {
            await handleDistrictChange(business_district);
          }
          // if (business_parliament) {
          //  await handleParChange(business_parliament);
          // }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplication();
  }, [_id]);
  const handleDistrictChange = async (id) => {
    formik.setFieldValue("district", id);
    const respose = await lacData(id);
    const res = await tehsilDataDistrictWise(id);
    if (respose?.data?.success) {
      formik.setFieldValue("lacList", respose?.data?.data);
    }
    if (res?.data?.success) {
      formik.setFieldValue("tehsilList", res.data?.data?.tehsil);
      formik.setFieldValue("blockList", res?.data?.data?.block);
    }
  };
  const handleParChange = async (id) => {
    formik.setFieldValue("parliment", id);
    // try {
    //   const response = await contiTencyData(id);
    //   if (response?.data?.success) {
    //     formik.setFieldValue("constiList", response?.data?.data);
    //   }
    // } catch (error) {
    //   console.error("Error fetching constituencies:", error);
    // }
  };
  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Business Details
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          spacing={2}
          // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Business Entity Type :
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="bet">Business Entity Type</InputLabel>
              <Select
                margin="dense"
                required
                fullWidth
                id="bet"
                label="Select Priority Sector"
                placeholder="Select Priority Sector"
                name="bet"
                value={formik.values.bet}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bet && Boolean(formik.errors.bet)}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {betList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.bet &&
                formik.submitCount > 0 && ( // Show error only on submit
                  <Typography
                    variant="h6"
                    sx={{ color: "#d32f2f", fontSize: "13px" }}
                  >
                    {formik.errors.bet}
                  </Typography>
                )}
            </FormControl>
          </Grid>

          {/* Inspiration Behind Idea */}
          <Grid item md={12} lg={12} xl={12} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Business Address (Registered Office):
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                fullWidth
                id="business_address"
                label="Business Address"
                name="business_address"
                autoComplete="Inspiration Behind Idea"
                type="textarea"
                rows={2}
                multiline
                value={formik.values.business_address}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={
                //   formik.touched.business_address &&
                //   Boolean(formik.errors.business_address)
                // }
                // helperText={
                //   formik.touched.business_address &&
                //   formik.errors.business_address
                // }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              State
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">State/ UT</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="state"
                label="State/ UT"
                placeholder="State"
                name="state"
                // autoFocus
                value={formik.values.state}
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                // error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {stateList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              District:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">District</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="district"
                label="District"
                placeholder="District"
                name="district"
                // autoFocus
                value={formik.values.district}
                onChange={(e) => {
                  e.preventDefault();
                  handleDistrictChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
                autoComplete="off"
                // onChange={(e) => handleChange(e)}
              >
                {districtList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.district &&
                formik.submitCount > 0 && ( // Show error only on submit
                  <Typography
                    variant="h6"
                    sx={{ color: "#d32f2f", fontSize: "13px" }}
                  >
                    {formik.errors.district}
                  </Typography>
                )}
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Legislative Assembly Constituency:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="lac">
                Legislative Assembly Constituency
              </InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="lac"
                label=" Legislative Assembly Constituency"
                placeholder=" Legislative Assembly Constituency"
                name="lac"
                // autoFocus
                value={formik.values.lac}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lac && Boolean(formik.errors.lac)}
                // helperText={formik.touched.lac && formik.errors.lac}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {formik?.values?.lacList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Parliament Constituency:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="parliment">Parliament Constituency</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="parliment"
                label="Parliament Constituency"
                placeholder="Parliament"
                name="parliment"
                // autoFocus
                value={formik.values.parliment}
                onChange={(e) => {
                  e.preventDefault();
                  handleParChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.parliment && Boolean(formik.errors.parliment)
                }
                // helperText={formik.touched.parliment && formik.errors.parliment}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {pcList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Tehsil:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Tehsil</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="tehsil"
                label="tehsil"
                placeholder="Tehsil"
                name="tehsil"
                // autoFocus
                value={formik.values.tehsil}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.tehsil && Boolean(formik.errors.tehsil)}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {formik?.values?.tehsilList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Block:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Block</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="block"
                label="block"
                placeholder="Block"
                name="block"
                // autoFocus
                value={formik.values.block}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.block && Boolean(formik.errors.block)}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {formik?.values?.blockList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item md={6} lg={6} xl={6} xs={12}>
            <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Constituency:
            </Typography>
            <br />
            <FormControl fullWidth>
              <InputLabel id="pc">Constituency</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="constituency"
                label="Constituency"
                placeholder="Constituency"
                name="constituency"
                // autoFocus
                value={formik.values.constituency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.constituency && Boolean(formik.errors.constituency)}
                helperText={formik.touched.constituency && formik.errors.constituency}
                autoComplete="Register type"
                // onChange={(e) => handleChange(e)}
              >
                {formik?.values?.constiList?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              PIN:
            </Typography> */}
            <TextField
              margin="dense"
              type="number"
              inputProps={{ min: 0 }}
              fullWidth
              id={`pin`}
              label="PIN"
              name={`pin`}
              placeholder="PIN"
              autoComplete={"off"}
              value={formik.values?.pin}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.pin && Boolean(formik.errors.pin)}
              // helperText={formik.touched.pin && formik.errors.pin}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              GST Number:
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              id={`gst_no`}
              label="GST Number"
              name={`gst_no`}
              placeholder="GST Number"
              autoComplete={"off"}
              value={formik.values?.gst_no?.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.gst_no && Boolean(formik.errors.gst_no)}
              helperText={formik.touched.gst_no && formik.errors.gst_no}
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Incorporation Number:
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              id={`incorporation_number`}
              label="Incorporation Number"
              name={`incorporation_number`}
              placeholder="Incorporation Number"
              autoComplete={"off"}
              value={formik.values?.incorporation_number}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={
              //   formik.touched.incorporation_number &&
              //   Boolean(formik.errors.incorporation_number)
              // }
              // helperText={
              //   formik.touched.incorporation_number &&
              //   formik.errors.incorporation_number
              // }
            />
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Udyam Number :
            </Typography> */}
            <TextField
              margin="dense"
              fullWidth
              id={`udyam_no`}
              label="Udyam Number"
              name={`udyam_no`}
              placeholder="Udyam Number"
              autoComplete={"off"}
              value={formik.values?.udyam_no}
              onChange={formik.handleChange}
              // onBlur={formik.handleBlur}
              // error={formik.touched.udyam_no && Boolean(formik.errors.udyam_no)}
              // helperText={formik.touched.udyam_no && formik.errors.udyam_no}
            />
          </Grid>
          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} gap={2} className="button-right">
           <Button
            type="button"
            variant="contained"
            onClick={handleBack}
            disabled={active === 0}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          >
            Previous
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleNext}
            disabled={active === steps.length}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="end"
          >
            <span>Next</span>
          </LoadingButton>
          </Grid> */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={(e) => handleNext(e)}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BusinessDetails;
