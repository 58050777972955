import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  api,
  useCreateApplicantMutation,
  useCreateRecognitionMutation,
  useGetSectorQuery,
  useGetSubSectorQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";

const BusinessPlanDuplicate = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("recognition_id");
  const [loading, setLoading] = React.useState(false);
  const { data: sectorList, isLoading, isError } = useGetSectorQuery();
  const { data: sub_sectorList } = useGetSubSectorQuery();
  const [recognitionCreate] = useCreateRecognitionMutation();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const validationSchema = Yup.object({
    executive_summary: Yup.string().required("Executive Summary is required"),
    market_analysis: Yup.string().required("Market analysis of  is required"),
    products_description: Yup.string().required(
      "Products/service description of  is required"
    ),
    marketing_sales_strategy: Yup.string().required(
      "Marketing Sales Strategy of  is required"
    ),
    financial_projections: Yup.string().required(
      "financial projections of  is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      executive_summary: "",
      market_analysis: "",
      products_description: "",
      marketing_sales_strategy: "",
      financial_projections: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // ... (your existing code)
        let data = {
          step: "business_plan",
          payload: {
            executive_summary: values?.executive_summary,
            market_analysis: values?.market_analysis,
            products_services_description: values?.products_description,
            marketing_sales_strategy: values?.marketing_sales_strategy,
            financial_projections: values?.financial_projections,
            application_id: _id,
          },
        };

        const response = await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success("Business Plan Created Sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });
  const handleNext = () => {
    formik.handleSubmit();
    // Remove setLoading(false) from here
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };

  React.useEffect(() => {
    const UpdateRecognition = async () => {
      if (_id) {
        try {
          const { data } = await getSingleRecognition(_id);
          const {
            executive_summary,
            market_analysis,
            products_services_description,
            marketing_sales_strategy,
            financial_projections,
          } = data?.data;
          formik.setValues({
            executive_summary,
            market_analysis,
            products_description: products_services_description,
            marketing_sales_strategy: marketing_sales_strategy,
            financial_projections: financial_projections,
          });
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateRecognition();
  }, [_id]);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Business Plan
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Company Name */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Executive Summary
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                type="textarea"
                rows={2}
                multiline
                id="executive_summary"
                label="Executive Summary"
                placeholder="Executive Summary"
                name="executive_summary"
                autoComplete="off"
                value={formik.values.executive_summary}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.executive_summary &&
                  Boolean(formik.errors.executive_summary)
                }
                helperText={
                  formik.touched.executive_summary &&
                  formik.errors.executive_summary
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Market Analysis:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="market_analysis"
                label=" Market Analysis"
                placeholder="Market Analysis"
                name="market_analysis"
                autoComplete="off"
                value={formik.values.market_analysis}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.market_analysis &&
                  Boolean(formik.errors.market_analysis)
                }
                helperText={
                  formik.touched.market_analysis &&
                  formik.errors.market_analysis
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Products/Services Description:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="products_description"
                label=" Products/Services Description"
                placeholder=" Products/Services Description"
                name="products_description"
                autoComplete="off"
                // type="textarea"
                // rows={3}
                // multiline
                value={formik.values.products_description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.products_description &&
                  Boolean(formik.errors.products_description)
                }
                helperText={
                  formik.touched.products_description &&
                  formik.errors.products_description
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Marketing and Sales Strategy:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="details_of_funding"
                label="Marketing and Sales Strategy"
                placeholder="Marketing and Sales Strategy"
                name="marketing_sales_strategy"
                autoComplete="off"
                // type="textarea"
                // rows={3}
                // multiline
                value={formik.values.marketing_sales_strategy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.marketing_sales_strategy &&
                  Boolean(formik.errors.marketing_sales_strategy)
                }
                helperText={
                  formik.touched.marketing_sales_strategy &&
                  formik.errors.marketing_sales_strategy
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Financial Projections:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="financial_projections"
                label=" Financial Projections"
                placeholder="Financial Projections"
                name="financial_projections"
                autoComplete="off"
                // type="textarea"
                // rows={3}
                // multiline
                value={formik.values.financial_projections}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.financial_projections &&
                  Boolean(formik.errors.financial_projections)
                }
                helperText={
                  formik.touched.financial_projections &&
                  formik.errors.financial_projections
                }
              />
            </FormControl>
          </Grid>

          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} gap={2} className="button-right">
          <Button
            type="button"
            variant="contained"
            onClick={handleBack}
            disabled={active === 0}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          >
            Previous
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleNext}
            disabled={active === steps.length - 1}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="center"
          >
            <span>Next</span>
          </LoadingButton>

          </Grid> */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

        {/* Form Actions */}
      </Box>
    </>
  );
};

export default BusinessPlanDuplicate;
