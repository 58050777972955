import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { api } from "../../services/api";
import { Typography } from "@mui/material";
import moment from "moment";

export default function NoOppositeContent({ id }) {
  //director === hod
  const [trackApplication] =
    api.endpoints.trackApplicationStatus.useLazyQuery();
  const [nodalStatus, setNodalStatus] = React.useState("");
  const [techStatus, setTechStatus] = React.useState("");
  const [mentorStatus, setMentorStatus] = React.useState("");
  const [dicStatus, setDicStatus] = React.useState("");
  const [hodStatus, setHodStatus] = React.useState("");
  const [userData, setUserData] = React.useState("");
  React.useEffect(() => {
    const UpdateApplication = async () => {
      if (id) {
        try {
          const { data } = await trackApplication(id);
          if (data?.success) {
            setNodalStatus(data?.application?.nodalstatus);
            setTechStatus(data?.application?.techstatus);
            setMentorStatus(data?.application?.mentorstatus);
            setDicStatus(data?.application?.dicstatus);
            setHodStatus(data?.application?.directoratestatus);
            setUserData(data?.application);
          }
        } catch (error) {
          console.error("Error fetching applicant data:", error);
        }
      }
    };
    UpdateApplication();
  }, [id]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              nodalStatus === "accepted"
                ? "success"
                : nodalStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                nodalStatus === "accepted"
                  ? "green"
                  : nodalStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          Nodal Status
          {userData?.nodaluser?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.nodaluser?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.nodalremark !== null &&userData?.nodalremark !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.nodalremark}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.nodalstatusdate !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.nodalstatusdate).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              techStatus === "selected"
                ? "success"
                : techStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                techStatus === "selected"
                  ? "green"
                  : techStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          Technical Screeen Status
          {userData?.techuser?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.techuser?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.techremark !== null && userData?.techremark !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.techremark}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.techstatusdate !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.techstatusdate).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem>
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              mentorStatus === "accepted"
                ? "success"
                : mentorStatus === "reverted"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                mentorStatus === "accepted"
                  ? "green"
                  : mentorStatus === "reverted"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          Mentor Status
          {userData?.mentoruser?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.mentoruser?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.mentorremarks !== null && userData?.mentorremarks !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.mentorremarks}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.mentorstatusdate !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.mentorstatusdate).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem> */}
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              dicStatus === "approved"
                ? "success"
                : dicStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
          <TimelineConnector
            sx={{
              backgroundColor:
                dicStatus === "approved"
                  ? "green"
                  : dicStatus === "rejected"
                  ? "red"
                  : "grey",
            }}
          />
        </TimelineSeparator>
        <TimelineContent>
          DIC/GM Status
          {userData?.dicuser?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.dicuser?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.dicremark !== null && userData?.dicremark !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.dicremark}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.dicstatusdate !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.dicstatusdate).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem> */}
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              hodStatus === "approved"
                ? "success"
                : hodStatus === "rejected"
                ? "error"
                : "grey"
            }
          />
        </TimelineSeparator>
        <TimelineContent>
          HOD/Directorate Status
          {userData?.directorateruser?.name !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              // textAlign="center"
              fontSize={14}
              fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.secondary"
            >
              {"Name: " + userData?.directorateruser?.name}
            </Typography>
          ) : (
            ""
          )}
          {userData?.directorateremark !== null && userData?.directorateremark !== undefined ? (
            <Typography
              variant="h6"
              component="div"
              fontSize={12}
              fontFamily="Poppins, sans-serif"
              color="primary.main" // Default color
              sx={{
                "& .highlight": { color: "green" }, // Set your custom color here
              }}
            >
              <span className="highlight">
                {"Remarks: " + userData?.directorateremark}
              </span>
            </Typography>
          ) : (
            ""
          )}
          {userData?.directoraterstatusdate !== null ? (
            <Typography
              variant="h6"
              component="div"
              //  textAlign="center"
              fontSize={12}
              //  fontWeight="bold"
              fontFamily="Poppins, sans-serif"
              color="primary.main"
            >
              {"Date: " + moment(userData?.directoraterstatusdate).format("LL")}
            </Typography>
          ) : (
            ""
          )}
        </TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}
