import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  api,
  useCreateApplicantMutation,
  useCreateRecognitionMutation,
  useGetSectorQuery,
  useGetSubSectorQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
const validationSchema = Yup.object({
  initial_investment
  : Yup.string().required("Initial Investment is required"),
  revenue: Yup.string().required("Revenue is required"),
  funding_received: Yup.string().required("Funding Details is required"),
  funding_details: Yup.string().required("Uniqueness is required"),
});
const  FinancialInformationDuplicate= ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("recognition_id");
  const [loading, setLoading] = React.useState(false);
  const { data: sectorList, isLoading, isError } = useGetSectorQuery();
  const { data: sub_sectorList } = useGetSubSectorQuery();
  const [getSingleRecognition] = api.endpoints.singleRecognition.useLazyQuery();
  const [recognitionCreate] = useCreateRecognitionMutation();
  const wordCount = (str) => {
    if (!str) return 0;
    return str.trim().split(/\s+/).length;
  };

  const formik = useFormik({
    initialValues: {
      initial_investment: "",
      revenue: "",
      funding_received: "",
      funding_details: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let data = {
          step: "financial",
          payload: {
            initial_investment: values.initial_investment,
            revenue: values?.revenue,
            funding_received: values?.funding_received,
            funding_details: values?.funding_details,
            application_id: _id,
          },
        };

        const response =  await recognitionCreate(data);
        if (response?.data?.success) {
          toast.success("Financial Information Created Sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });
  const handleNext = () => {
    formik.handleSubmit();
    // Remove setLoading(false) from here
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };

  React.useEffect(() => {
    const UpdateRecognition = async () => {
      if (_id) {
        try {
          const { data } = await getSingleRecognition(_id);
          const {
            initial_investment,
            revenue,
            funding_received,
            funding_details,
          } = data?.data;
          formik.setValues((prev)=>({
            ...prev,
            initial_investment,
            revenue,
            funding_received,
            funding_details
          }));
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateRecognition();
  }, [_id]);

  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Financial Information
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Company Name */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Initial Investment :
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="initial_investment"
                label="Initial Investment"
                placeholder="Initial Investment"
                name="initial_investment"
                autoComplete="Company Name"
                value={formik.values.initial_investment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.initial_investment &&
                  Boolean(formik.errors.initial_investment)
                }
                helperText={
                  formik.touched.initial_investment &&
                  formik.errors.initial_investment
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Revenue :
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="revenue"
                label="Revenue"
                name="revenue"
                autoComplete="Company Name"
                value={formik.values.revenue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.revenue && Boolean(formik.errors.revenue)}
                helperText={formik.touched.revenue && formik.errors.revenue}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Funding Received :
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="funding_received"
                label="Social Impact"
                placeholder="Social Impact"
                name="funding_received"
                autoComplete="off"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.funding_received}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.funding_received &&
                  Boolean(formik.errors.funding_received)
                }
                helperText={
                  formik.touched.funding_received &&
                  formik.errors.funding_received
                }
              />
            </FormControl>
          </Grid>
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: "18px" }}
            >
              Details of Funding:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                required
                fullWidth
                id="funding_details"
                label="Details of Funding"
                placeholder="Details of Funding"
                name="funding_details"
                autoComplete="funding_details"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.funding_details}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.funding_details &&
                  Boolean(formik.errors.funding_details)
                }
                helperText={
                  formik.touched.funding_details &&
                  formik.errors.funding_details
                }
              />
            </FormControl>
          </Grid>

          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} gap={2} className="button-right">
          <Button
            type="button"
            variant="contained"
            onClick={handleBack}
            disabled={active === 0}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
          >
            Previous
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleNext}
            disabled={active === steps.length - 1}
            sx={{ mt: 3, mb: 2, borderRadius: 10 }}
            endIcon={<SendIcon />}
            loading={loading}
            loadingPosition="center"
          >
            <span>Next</span>
          </LoadingButton>

          </Grid> */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

        {/* Form Actions */}
      </Box>
    </>
  );
};

export default FinancialInformationDuplicate;
