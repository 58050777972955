import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Switch,
  Stack,
  Chip,
  Button,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import {
  useAllApplicantQuery,
  useDeleteApplicantMutation,
  useGetApplicationQuery,
  useGetMentorQuery,
  useIncubationDetailsQuery,
} from "../../services/api";
import InfoIcon from "@mui/icons-material/Info";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import FlexBetween from "../../Components/FlexBetween";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast, ToastContainer } from "react-toastify";
import { Add, Delete, Edit } from "@mui/icons-material/";
import moment from "moment";
import swal from "sweetalert";
import { styled } from "@mui/material/styles";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const MentorDashboard = () => {
  const { data: allApplicant, isError } = useGetApplicationQuery();
  const { data: incubationUser } = useIncubationDetailsQuery();
  const [applicantDelete] = useDeleteApplicantMutation();
  const location = useLocation();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const role = localStorage.getItem("role").toLowerCase();
  const { data: mentorList, isLoading } = useGetMentorQuery({
    data: role === "mentor" ? "mentor_list" : "all_list",
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
  }); // Get Incubation list

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Do you want delete the Application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        applicantDelete(id);
        swal("Application is deleted Successfully.!", {
          icon: "success",
        });
      } else {
        swal("Application is safe!");
      }
    });
  };

  const handleEdit = (_id) => {
    localStorage.setItem("application_id", _id);
    navigate(`/applicant`);
  };

  const handleMentor = (id) => {
    navigate(`/select-mentor/${id}`);
  };

  const handleClickOpen = (id) => {
    navigate(`/progress-report/${id}`);
  };

  const getActionsWrapper = ({ params = null, role = null }) => {
    const actions = [];
    switch (role) {
      case "mentor":
      case "hod":
        actions.push(
          // <GridActionsCellItem
          //   icon={<Edit />}
          //   onClick={(event) => {
          //     navigate(location.pathname + `/upsert/${params.row._id}`);
          //   }}
          //   label="Edit"
          // />,
          <Button
            onClick={() => navigate(`/mentor-register/preview/${params?.id}`)}
            title="View Mentor"
            variant="contained"
            size="small"
          >
           View Mentor Application 
          </Button>
          // <GridActionsCellItem
          //   icon={<VisibilityIcon />}
          //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          //   label="View"
          // />
        );
        break;

      // case "superadmin":
      // case "admin":
      // case "hod":
      //   actions.push(
      //     <GridActionsCellItem
      //       icon={<Edit />}
      //       onClick={(event) => {
      //         navigate(location.pathname + `/upsert/${params.row._id}`);
      //       }}
      //       label="Edit"
      //     />,
      //     <GridActionsCellItem
      //       icon={<Delete />}
      //       onClick={() => {
      //         handleDelete(params.row._id);
      //       }}
      //       label="Delete"
      //     />,
      //     <Button
      //       onClick={() => navigate(`/mentor-register/preview/${params?.id}`)}
      //       title="View Mentor"
      //       variant="contained"
      //       size="small"
      //     >
      //       View Mentor
      //     </Button>
      //   );

      //   break;

      default:
        break;
    }

    // Common actions regardless of role
    // if (params?.row?.finalSubmit && params?.row?.copy_to_recognition) {
    //   actions.push(
    //     <Button
    //       onClick={() => navigate(`/applicant/preview/${params?.id}`)}
    //       title="View Application"
    //       variant="contained"
    //       size="small"
    //     >
    //       View Application
    //     </Button>,
    //     <Button
    //       title="Copy to Recognition"
    //       variant="contained"
    //       size="small"
    //       // onClick={() => handleMentor(params.row._id)}
    //     >
    //       Copy to Recognition
    //     </Button>
    //   );
    // }
    return actions;
  };
  const getActionsWrapper1 = ({ params = null, role = null }) => {
    const actions = [];
    if (!params?.row?.finalSubmit && role === "user") {
      actions.push(
        <Button
          onClick={() => {
            handleEdit(params.row._id);
          }}
        title="Edit"
        variant="contained"
        size="small"
        color="inherit"
      >
        {<Edit color={"success"} />}&nbsp; Edit
      </Button>,
      <Button
        onClick={() => handleDelete(params.row._id)}
        title="Delete"
        variant="contained"
        size="small"
        color="error"
      >
        {<Delete />}&nbsp; Delete
      </Button>,
        // <GridActionsCellItem
        //    color="success"
        //   icon={<Edit />}
        //   onClick={() => {
        //     handleEdit(params.row._id);
        //   }}
        //   label="Edit"
        // />,
        // <GridActionsCellItem
        //    color="primary"
        //   icon={<VisibilityIcon />}
        //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
        //   label="View"
        // />
      );
    } else if (
      params?.row?.finalSubmit &&
      role === "user" &&
     ( params?.row?.status === "selected" &&
      params?.row?.mantor_id === null)
    ) {
      actions.push(
        <GridActionsCellItem
           color="primary"
          icon={<VisibilityIcon />}
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          label="View"
        />,
        <Button
          onClick={() => {
            handleMentor(params.row._id);
          }}
          title="Select Mentor"
          variant="contained"
          size="small"
        >
          Select Mentor
        </Button>
      );
    } else if (
      role === "user" &&
     ( params?.row?.status === "selected" &&
      params?.row?.mantor_id)
    ) {
      actions.push(
        <GridActionsCellItem
           color="primary"
          icon={<VisibilityIcon />}
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          label="View"
        />,
        <Button
          onClick={() => {
            handleClickOpen(params.row._id);
          }}
          title="Change Status"
          variant="contained"
          size="small"
        >
          Generate PR
        </Button>
      );
    } else if (params?.row?.finalSubmit && (role === "incubation"||role==="mentor"||role==="hod"||role==="admin")) {
      actions.push(
        // <GridActionsCellItem
        //   icon={<VisibilityIcon />}
        //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
        //   label="View"
        // />,
        // <Button
        //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
        //   title="View PR"
        //   variant="contained"
        //   size="small"
        // >
        //   View Application
        // </Button>,
        <Button
          onClick={() => navigate(`/progress-report/${params?.id}`)}
          title="View PR"
          variant="contained"
          size="small"
        >
          View PR
        </Button>
      );
    } else if (
      params?.row?.finalSubmit &&
      (role === "superadmin" || role === "admin" || role === "hod")
    ) {
      actions.push(
        <GridActionsCellItem
           color="success"
          icon={<Edit />}
          onClick={() => {
            handleEdit(params.row._id);
          }}
          title="Edit Application"
          label="Edit"
        />,
        <GridActionsCellItem
           color="error"
          icon={<Delete />}
          onClick={() => {
            handleDelete(params.row._id);
          }}
          label="Delete"
          title="Delete Application"
        />,
        <Button
          onClick={() => navigate(`/applicant/preview/${params?.id}`)}
          title="View Application"
          variant="contained"
          size="small"
        >
          View Application
        </Button>
        // <GridActionsCellItem
        //   icon={<VisibilityIcon />}
        //   onClick={() => navigate(`/applicant/preview/${params?.id}`)}
        //   label="View"
        // />
      );
    }
    return actions;
  };


  const columns = useMemo(() => {
    return [
      {
        field: "country",
        headerName: "Country",
        flex: 1,
      },
      {
        field: "city",
        headerName: "City",
        flex: 1,
      },
      {
        field: "current_organization",
        headerName: "Organization",
        // renderCell: (params) => {
        //   return <Typography>{params?.row?.roleId?.name}</Typography>;
        // },
        flex: 1,
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   getActions: (params) => getActionsWrapper({ params, role }),
      //   flex: 1,
      // },
    ];
  }, []);
  const columns1 = useMemo(
    () => [
      {
        field: "founder_name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "contact",
        headerName: "Mobile Number",
        flex: 1,
      },
      // {
      //   field: "actions",
      //   headerName: "Actions",
      //   type: "actions",
      //   flex: 1,
      //   getActions: (params) => getActionsWrapper1({ params, role }),
      // },
    ],
    [role]
  );
  const handlePageSizeChange = useCallback(
    (data) => {
      setPaginationModel(data);
    },
    [setPaginationModel]
  );
  return (
    <Box marginTop={"6em"} padding={4}>
      <Box
        sx={{
          justifyContent: "space-between",
          flex: 1,
          width: "100%",
          backgroundColor: (theme) => theme.palette.default,
          color: (theme) => theme.palette.text.primary,
          borderRadius: "4px",
        }}
      >
        <ToastContainer />
        {/* Main content */}
        <Box sx={{ width: "100%" }}>
          {/* Main content */}
          {/* <Container sx={{ width: "100%", overflow: "auto" }}> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Card className="card-stats">
                <CardContent className="card-stats-content">
                  <Box className="card-icon card-warning">
                    <TextSnippetIcon
                      className="card-ic"
                      sx={{ color: "#ffffff " }}
                    />
                  </Box>
                  <Typography className="card-stats-title">
                    Total Mentor Application
                  </Typography>
                  <Typography className="card-stats-items">
                    {mentorList?.data?.totalItems}
                  </Typography>
                </CardContent>
                <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/mentor-register`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
              </Card>
            </Grid>
            {role === "mentor" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-rose">
                      <QueryBuilderIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Pending Mentor Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {mentorList?.data?.statusCounts?.pending}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/mentor-register`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {role === "mentor" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-success">
                      <CheckCircleOutlineIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Approved Mentor Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {mentorList?.data?.statusCounts?.approved}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/mentor-register`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            {role === "mentor" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-warning">
                      <InfoIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Rejected Mentor Application
                    </Typography>
                    <Typography className="card-stats-items">
                      {mentorList?.data?.statusCounts?.rejected}
                    </Typography>
                  </CardContent>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    className="card-stats-footer"
                  >
                    <Grid>
                      <Typography mt={1}>View</Typography>
                    </Grid>
                    <Grid>
                      <Tooltip title="Application Information">
                        <IconButton
                          onClick={() => navigate(`/mentor-register`)}
                          color="primary"
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) : (
              <></>
            )}

            {/* {role === "superadmin" ? (
              <Grid item xs={12} md={3}>
                <Card className="card-stats">
                  <CardContent className="card-stats-content">
                    <Box className="card-icon card-info">
                      <EventRepeatIcon
                        className="card-ic"
                        sx={{ color: "#ffffff " }}
                      />
                    </Box>
                    <Typography className="card-stats-title">
                      Total Incubation
                    </Typography>
                    <Typography className="card-stats-items">
                      {incubationUser?.total}
                    </Typography>
                  </CardContent>
                  <Typography className="card-stats-footer">View</Typography>
                </Card>
              </Grid>
            ) : (
              <></>
            )} */}
          </Grid>
        </Box>

        {/* Table */}
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.text.primary,
          borderRadius: 2,
        }}
      >
         <Box sx={{ width: "100%", marginTop: 1, borderRadius: 2 }}>
          <Card
            sx={{
              marginBottom: 2,
              //  borderRadius: 2
            }}
          >
            <CardHeader
              sx={{
                backgroundColor: "#1976d2",
                color: "common.white",
              }}
              title={
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontWeight: "bold" }}
                >
                  Mentor List
                </Typography>
              }
            />
            <CardContent>{/* Table goes here */}</CardContent>
           
            <Box marginTop={2} sx={{  width: '100%', height:'350px' ,overflowX:'auto'}}>
              <DataGrid
                columns={columns}
                autoHeight
                getRowId={(row) => row._id}
                rowCount={mentorList?.total || 0}
                pageSizeOptions={[10, 25, 50, 100]}
                loading={isLoading}
                pagination
                columnBuffer={columns.length + 1}
                rowBuffer={10}
                paginationModel={paginationModel}
                paginationMode="server"
                onPageSizeChange={(newPageSize) =>
                  handlePageSizeChange(newPageSize)
                }
                onPaginationModelChange={(data) => handlePageSizeChange(data)}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  toolbar: GridToolbar,
                }}
                sx={{
                  "--DataGrid-overlayHeight": "100%",
                  width: "100%",
                  marginBottom: "16px",
                }}
                rows={mentorList?.data?.getAllMentor || []}
              />
            </Box>
          </Card>
        </Box>
        <Box sx={{ width: "100%", marginTop: 1, borderRadius: 2 }}>
          <Card
            sx={{
              marginBottom: 2,
              //  borderRadius: 2
            }}
          >
            <CardHeader
              sx={{
                backgroundColor: "#1976d2",
                color: "common.white",
              }}
              title={
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontWeight: "bold" }}
                >
                  Application List
                </Typography>
              }
            />
            <CardContent>{/* Table goes here */}</CardContent>
           
            <Box marginTop={2}  sx={{  width: '100%', height:'350px' }}>
            <DataGrid
              columns={columns1}
              rowHeight={50}
              rowCount={allApplicant?.total || 0}
              getRowId={(row) => row?._id}
              pageSizeOptions={[10, 25, 50, 100]}
              // loading={isLoading}
              pagination
              // paginationModel={paginationModel}
              paginationMode="server"
              onPageSizeChange={(newPageSize) => {}}
              onPaginationModelChange={(data) => {}}
              slots={{
                // noRowsOverlay: CustomNoRowsOverlay,
                toolbar: GridToolbar,
              }}
              sx={{
                "--DataGrid-overlayHeight": "100%",
                width: "100%",
                // marginBottom: '16px',
                // borderRadius: 2,
              }}
              rows={allApplicant?.data.AllApplication || []}
            />
            </Box>
          </Card>
        </Box>
       
      </Box>
    </Box>
  );
};

export default MentorDashboard;
