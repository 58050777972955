import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
// import {
//   api,
//   useAllApplicantQuery,
//   useSingleApplicantQuery,
//   useUpdateApplicantStatusMutation,
// } from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import { useChangeStatusByTechnicalMutation } from "../../services/api";

const validationSchema = Yup.object().shape({
  status: Yup.string().required("New Status is required"),
  remarks: Yup.string().required("Remarks is required").max(100, "Remarks must be 100 characters or less"),
});

const ChangeApplicationStatusDialog = ({ open, setOpen, handleClose,selectedId }) => {
const [sendToTechnical] = useChangeStatusByTechnicalMutation();
  const formik = useFormik({
    initialValues: {
      status: "",
      remarks: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission
    },
  });
//   React.useEffect(() => {
//     async function apiData() {  
//       if (selectedRow) {
//         await singleApplicant(selectedRow?._id);
//         formik.setValues((prev)=>({
//           ...prev,
//           status: allApplicant?.application?.status ,
//           remarks: allApplicant?.application?.remarks ,
//         }));
//       }
//     }
//     apiData()
//   }, [selectedRow?._id, allApplicant?.application, open]);
  
//  const handleNodalSection = async () => {
//      setLoading(true);
   
//      // Ensure that nodal_remarks doesn't exceed 100 characters before proceeding
//      const remarks = formik.values.nodal_remarks;
//      if (remarks.length > 100) {
//        toast.error("Remarks must be 100 characters or less", {
//          position: "top-center",
//          autoClose: 3000,
//          hideProgressBar: false,
//          closeOnClick: true,
//          pauseOnHover: true,
//          draggable: true,
//          progress: undefined,
//          theme: "light",
//        });
//        setLoading(false);
//        return;
//      }
   
//      try {
//        let data = {
//          id,
//          payload: {
//            status: formik.values.nodal_status,
//            remarks: remarks,
//          },
//        };
   
//        const response = await sendToNodal(data);
   
//        if (response?.data?.success) {
//          formik.setFieldValue("nodal_success", response.data.status);
//          toast.success(response.data.message, {
//            position: "top-center",
//            autoClose: 2000,
//            hideProgressBar: false,
//            closeOnClick: true,
//            pauseOnHover: true,
//            draggable: true,
//            progress: undefined,
//            theme: "light",
//          });
//          setTimeout(() => {
//            navigate("/agenda");
//          }, 2000);
//        } else if (response?.error) {
//          toast.error(response?.error?.data?.message, {
//            position: "top-center",
//            autoClose: 3000,
//            hideProgressBar: false,
//            closeOnClick: true,
//            pauseOnHover: true,
//            draggable: true,
//            progress: undefined,
//            theme: "light",
//          });
//          setLoading(false);
//        }
//      } catch (error) {
//        console.error("Error submitting data:", error);
//      } finally {
//        setTimeout(() => {
//          setLoading(false);
//        }, 500);
//      }
//    };
  const handleStatusChange = async () => {
   
    // Ensure that nodal_remarks doesn't exceed 100 characters before proceeding
    const remarks = formik.values.remarks;
    if (remarks.length > 100) {
      toast.error("Remarks must be 100 characters or less", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  
        try {
          let data = {
            id:selectedId,
            payload: {
              status: formik.values.status,
              remarks: formik.values.remarks,
            },
          };
          const response = await sendToTechnical(data);
          if (response?.data?.success) {
            toast.success(response.data.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleClose()
            setOpen(false)
            // setTimeout(() => {
            //   navigate("/application-status");
            // }, 1000);
          } else if (response?.error) {
            toast.error(response?.error?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            handleClose()
            setOpen(false)
          }
        } catch (error) {
          console.error("Error submitting data:", error);
        } finally {
        }
  };

  return (
    <>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} sx={{ zIndex: 10 }}>
        <DialogTitle>Technical Screening</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select Status
          </DialogContentText>
          <FormControl fullWidth sx={{ marginTop: 2 }}>
            {/* <InputLabel id="Select Status">Status</InputLabel> */}
            <Select
              labelId="Select Status"
              required
              fullWidth
              id="status"
              // label="Select Status"
              name="status"
              autoComplete="off"
              placeholder="Status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.status && Boolean(formik.errors.status)
              }
              helperText={formik.touched.status && formik.errors.status}
            >
              <MenuItem value="">--Select Status--</MenuItem>
              <MenuItem value="reverted">Reverted</MenuItem>
              {/* <MenuItem value="rejected">Rejected</MenuItem> */}
              <MenuItem value="selected">Selected</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth >
          <TextField
            type="textarea"
            rows={3}
            multiline
            label="Remarks"
            required
            variant="outlined"
            fullWidth
            margin="normal"
            name="remarks"
            id="remarks"
            value={formik.values.remarks}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
            helperText={formik.touched.remarks && formik.errors.remarks}
          />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              borderRadius: "5px",
              backgroundColor: "#CCCCCC",
              marginRight: "8px",
              color: "#000",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleStatusChange}
            sx={{
              borderRadius: "5px",
              backgroundColor: "#3498db",
              color: "#000",
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeApplicationStatusDialog;
