import React, { useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Select,
  InputLabel,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  api,
  useCreateApplicantMutation,
  useGetIncubationQuery,
  useGetSectorQuery,
  useGetSubSectorQuery,
} from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { ThemeContext } from "../../services/ThemeContext";

const StartupDetails = ({ steps, active, setActive }) => {
  const _id = localStorage.getItem("application_id");
  const [loading, setLoading] = React.useState(false);
  const {
    data: sectorList,
    isLoading: sectorLoading,
    isError: sectorError,
  } = useGetSectorQuery();
  const { data: subSectorList, isLoading: subSectorLoading } =
    useGetSubSectorQuery();
  // const { data: incubationList, isLoading: incubationLoading } =
  //   useGetIncubationQuery();
  const [newApplicant] = useCreateApplicantMutation();
  const [getSingleApplicant] = api.endpoints.singleApplicant.useLazyQuery();
  const [getListIncubation] = api.endpoints.incubationGetList.useLazyQuery();
  const { fontSize } = useContext(ThemeContext);
  const wordCount = (str) => {
    if (!str) return 0;
    return str.trim().split(/\s+/).length;
  };

  const validationSchema = Yup.object({
    name_of_the_startup: Yup.string().nullable(),
    sector: Yup.string().required("Sector is required"),
    sub_sector: Yup.string().required("Sub-sector is required"),
    idea: Yup.string()
      .nullable()
      .test(
        "wordCount",
        "Must be 100 words or less",
        (value) => !value || value.trim().split(/\s+/).length <= 100
      ),
    social_impact: Yup.string()
      .nullable()
      .test(
        "wordCount",
        "Must be 100 words or less",
        (value) => !value || value.trim().split(/\s+/).length <= 100
      ),
    uniqueness: Yup.string()
      .nullable()
      .test(
        "wordCount",
        "Must be 100 words or less",
        (value) => !value || value.trim().split(/\s+/).length <= 100
      ),
    incubation_center: Yup.string().required("Incubation center is required"),
  });

  const formik = useFormik({
    initialValues: {
      name_of_the_startup: "",
      sector: "",
      sub_sector: "",
      idea: "",
      social_impact: "",
      uniqueness: "",
      incubation_center: "",
      incubationList: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          step: "startup",
          payload: {
            startup_name: values.name_of_the_startup,
            application_id: _id,
            sector: values.sector,
            sub_sector: values.sub_sector,
            idea: values.idea,
            incubation_centre: values.incubation_center,
            social_public: values.social_impact,
            uniqueness: values.uniqueness,
          },
        };

        const response = await newApplicant(data);
        if (response?.data?.success) {
          toast.success(
            _id
              ? "Startup Details Updated Sucessfully"
              : "Startup Details Created Sucessfully",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setTimeout(() => {
            setActive((prevActive) => prevActive + 1);
          }, 3000);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  console.log("🚀 ~ handleNext ~ formik.isValid:", formik.errors);
  const handleNext = (e) => {
    e.preventDefault();
    setLoading(true);
    formik.handleSubmit();
if(!formik.isValid){

  setTimeout(()=>{
    setLoading(false);
  },1000)
}
  };

  const handleBack = () => {
    setActive((prevActive) => prevActive - 1);
  };
  React.useEffect(() => {
    const UpdateApplicant = async () => {
      if (_id) {
        try {
          const { data } = await getSingleApplicant(_id);
          const {
            startup_name,
            sector,
            sub_sector,
            idea,
            incubation_centre,
            social_public,
            uniqueness,
          } = data?.application;
          await formik.setValues((prev) => ({
            ...prev,
            name_of_the_startup: startup_name,
            sector,
            sub_sector,
            idea,
            social_impact: social_public,
            uniqueness,
            incubation_center: incubation_centre,
          }));
          if (sub_sector) {
            const incubation_data = await getListIncubation(sub_sector);
            if (incubation_data?.data?.success) {
              formik.setFieldValue("incubationList", incubation_data.data);
              if (incubation_data.data?.incubation_center) {
                formik.setFieldValue(
                  "incubation_center",
                  incubation_data.data.incubation_center
                );
              }
            }
          }
        } catch (error) {
          console.error("Error fetching permission data:", error);
        }
      }
    };
    UpdateApplicant();
  }, [_id]);
  const handleChange = async (event) => {
    const sub_sector = event.target.value;
    formik.setFieldValue("sub_sector", sub_sector);
    const incubation_data = await getListIncubation(event.target.value);
    if (incubation_data?.data?.success)
      formik.setFieldValue("incubationList", incubation_data?.data);
  };
  return (
    <>
      <ToastContainer />
      <Typography
        variant="h6"
        align="left"
        sx={{ fontWeight: 600, color: "#2074af" }}
      >
        Startup Details
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Grid
          container
          rowSpacing={1}
          spacing={2}
          // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          mt={2}
        >
          {/* Name of the Startup */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Name of the Startup:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                fullWidth
                id="name_of_the_startup"
                label="Name of the Startup"
                name="name_of_the_startup"
                autoComplete="Company Name"
                value={formik.values.name_of_the_startup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name_of_the_startup &&
                  Boolean(formik.errors.name_of_the_startup)
                }
                helperText={
                  formik.touched.name_of_the_startup &&
                  formik.errors.name_of_the_startup
                }
              />
            </FormControl>
          </Grid>

          {/* Sector */}
          <Grid item md={6} lg={6} xl={6} xs={12} mt={1}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Sector:
            </Typography> */}
            <FormControl fullWidth>
              <InputLabel id="sector">Select Sector</InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="sector"
                label="Select Sector"
                name="sector"
                value={formik.values.sector}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.sector && Boolean(formik.errors.sector)}
                error={formik.errors.sector && formik.submitCount > 0} // Show error only after submit
                // helperText={formik.touched.sector && formik.errors.sector}
              >
                {sectorList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.sector && formik.submitCount > 0 &&(
                <Typography
                  variant="h6"
                  sx={{ color: "#d32f2f", fontSize: "13px" }}
                >
                  {formik.errors.sector}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Sub Sector */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
  <br />
  <FormControl fullWidth>
    <InputLabel id="sub_sector">Select Sub Sector</InputLabel>
    <Select
      margin="dense"
      fullWidth
      id="sub_sector"
      label="Select Sub Sector"
      name="sub_sector"
      value={formik.values.sub_sector}
      onChange={(event) => handleChange(event)}
      onBlur={formik.handleBlur} // Optional, but keep it to track touch events if needed
      error={formik.errors.sub_sector && formik.submitCount > 0} // Show error only after submit
    >
      {subSectorList?.data?.map((item) => (
        <MenuItem value={item._id} key={item._id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
    {formik.errors.sub_sector && formik.submitCount > 0 && ( // Show error only on submit
      <Typography
        variant="h6"
        sx={{ color: "#d32f2f", fontSize: "13px" }}
      >
        {formik.errors.sub_sector}
      </Typography>
    )}
  </FormControl>
</Grid>

          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Incubation center:
            </Typography> */}
            <br />
            <FormControl fullWidth>
              <InputLabel id="incubation_center">
                Select Incubation Center
              </InputLabel>
              <Select
                margin="dense"
                fullWidth
                id="incubation_center"
                label="Select Incubation Center"
                name="incubation_center"
                value={formik?.values?.incubation_center}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.incubation_center && formik.submitCount > 0} 
                // helperText={
                //   formik.touched.incubation_center &&
                //   formik.errors.incubation_center
                // }
              >
                {formik?.values?.incubationList?.data?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.host_institute_name}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.incubation_center && formik.submitCount > 0 && (
                <Typography
                  variant="h6"
                  sx={{ color: "#d32f2f", fontSize: "13px" }}
                >
                  {formik.errors.incubation_center}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Idea */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Idea:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                fullWidth
                id="idea"
                label="Idea"
                placeholder="Idea"
                name="idea"
                autoComplete="idea"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.idea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.idea && Boolean(formik.errors.idea)}
                helperText={formik.touched.idea && formik.errors.idea}
              />
            </FormControl>
          </Grid>

          {/* Social Impact */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Social Impact/ Public welfare:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                fullWidth
                id="social_impact"
                label="Social Impact"
                placeholder="Social Impact"
                name="social_impact"
                autoComplete="off"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.social_impact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.social_impact &&
                  Boolean(formik.errors.social_impact)
                }
                helperText={
                  formik.touched.social_impact && formik.errors.social_impact
                }
              />
            </FormControl>
          </Grid>

          {/* Uniqueness */}
          <Grid item md={6} lg={6} xl={6} xs={12}>
            {/* <Typography
              variant="h6"
              align="left"
              sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}
            >
              Uniqueness:
            </Typography> */}
            <FormControl fullWidth>
              <TextField
                margin="dense"
                fullWidth
                id="uniqueness"
                label="Uniqueness"
                placeholder="Uniqueness"
                name="uniqueness"
                autoComplete="off"
                type="textarea"
                rows={3}
                multiline
                value={formik.values.uniqueness}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.uniqueness && Boolean(formik.errors.uniqueness)
                }
                helperText={
                  formik.touched.uniqueness && formik.errors.uniqueness
                }
              />
            </FormControl>
          </Grid>

          {/* Incubation Center */}

          {/* Form Actions */}
          {/* <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid> */}
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="button-right"
          >
            <Button
              type="button"
              variant="contained"
              onClick={handleBack}
              disabled={active === 0}
            >
              Previous
            </Button>
            <Box ml={2} display="inline-block">
              <LoadingButton
                variant="contained"
                onClick={handleNext}
                disabled={active === steps.length - 1}
                endIcon={<SendIcon />}
                loading={loading}
                loadingPosition="end"
                type="submit"
              >
                <span>Next</span>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StartupDetails;
